import React, { useCallback, useEffect, useMemo, useState } from "react";
import addIcon from "../assets/images/addIcon.svg";
import { ScaleLoader } from "react-spinners";
import Modal from "../components/Modal";
import Alert from "../components/Alert";
import { useDispatch, useSelector } from "react-redux";
import { addBuild, addWorkspace, updateBuild, updateWorkspace } from "../features/workspaceSlice";
import { Pencil } from "lucide-react";

const Workspaces = () => {
    const dispatch = useDispatch();
    const [alert, setAlert] = useState({ show: false, severity: "", message: "" });
    const [loading, setLoading] = useState(true); // Set initial loading state to true
    const [workspaceOpenModal, setWorkspaceOpenModal] = useState(false);
    const [officeOpenModal, setOfficeOpenModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isOfficeEditMode, setIsOfficeEditMode] = useState(false);
    const [workspacesWithCoworker, setWorkspacesWithCoworker] = useState([]);

    const { workspaces, buildings, types } = useSelector(state => state.workspace);
    const { attendances } = useSelector(state => state.attendance);
    const { assignments } = useSelector(state => state.assignment);
    const {data} = useSelector(state => state.user)

    const today = useMemo(() => new Date().toISOString().split('T')[0], []);
    const isAdmin = data.roles.includes("ROLE_ADMIN");

    const [workspaceData, setWorkspaceData] = useState({
        id: null,
        name: "",
        capacity: 1,
        type: null,
        building: null,
        description: "",
        available: true
    });

    const [officeData, setOfficeData] = useState({
        id: null,
        name: ""
    });

    const showAlert = (severity, message) => {
        setAlert({ show: true, severity, message });
        setTimeout(() => {
            setAlert({ show: false, severity: "", message: "" });
        }, 3000);
    };

    const handleEditWorkspace = (workspace) => {
        setWorkspaceData({
            id: workspace.id,
            name: workspace.name,
            capacity: workspace.capacity,
            type: `api/workspace_types/${workspace.type.id}`,
            building: `api/buildings/${workspace.building.id}`,
            description: workspace.description,
            available: workspace.available
        });
        setIsEditMode(true);
        setWorkspaceOpenModal(true);
    };

    const handleSubmitWorkspace = async (e) => {
        e.preventDefault();
        try {
            if (isEditMode) {
                dispatch(updateWorkspace({ id: workspaceData.id, data: workspaceData }));
                showAlert("success", "Workspace mis à jour avec succès!");
            } else {
                dispatch(addWorkspace({ data: workspaceData }));
                showAlert("success", "Workspace ajouté avec succès!");
            }
            setWorkspaceOpenModal(false);
        } catch (error) {
            showAlert("error", isEditMode ? "Echec lors de la mise à jour du workspace" : "Echec lors de l'ajout du workspace");
            console.error(error);
        }
    };

    const openOfficeModal = (office = null) => {
        if (office) {
            setOfficeData({
                id: office.id,
                name: office.name
            });
            setIsOfficeEditMode(true);
        } else {
            setOfficeData({
                id: null,
                name: ""
            });
            setIsOfficeEditMode(false);
        }
        setOfficeOpenModal(true);
    };

    const handleSubmitOffice = async (e) => {
        e.preventDefault();
        try {
            if (isOfficeEditMode) {
                dispatch(updateBuild({ data: { name: officeData.name }, id: officeData.id }));
                showAlert("success", "Bureau mis à jour avec succès!");
            } else {
                dispatch(addBuild({ data: officeData }));
                showAlert("success", "Bureau ajouté avec succès!");
            }
            setOfficeOpenModal(false);
        } catch (error) {
            showAlert("error", isOfficeEditMode ? "Echec lors de la mise à jour du bureau" : "Echec lors de l'ajout du bureau");
            console.error(error);
        }
    };

    const handleChangeWorkspace = (e) => {
        const { name, value } = e.target;
        setWorkspaceData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeOffice = (e) => {
        const { name, value } = e.target;
        setOfficeData(prevState => ({
            ...prevState,
            [name]: value
        }));
 };




    const getWorkspaceWithCoworkers = useCallback(() => {
        const attendancesOfToday = attendances.filter(a=> a.date.split('T')[0] === today.split('T')[0])

        const workspacesWithCoworkers = workspaces.map(workspace => {
            const matchingAttendance = attendances.find(attendance =>
                attendance.workspace.id === workspace.id &&
                attendance.present &&
                attendance.date.split('T')[0] === today
            );
            if (matchingAttendance) {
                return {
                    ...workspace,
                    coworker: matchingAttendance.coworker.surname
                };
            } else {
                return workspace;
            }
        });

        const workspacesOfAssignments = workspacesWithCoworkers.map(workspace => {
            const matchingAssignment = assignments.find(a => a.workspace.id === workspace.id);
            const absent = attendancesOfToday.some(attendance => attendance?.booking?.id === matchingAssignment?.booking?.id && !attendance.present);
         
            if (matchingAssignment && !absent) {
                return {
                    ...workspace,
                    coworker : matchingAssignment.booking.coworker.surname
                }
            }else {
                return workspace
            }
        })
        setWorkspacesWithCoworker(workspacesOfAssignments)


    }, [attendances, workspaces, today]);

    useEffect(() => {
        getWorkspaceWithCoworkers();
        setLoading(false)
    }, [workspaces, attendances, getWorkspaceWithCoworkers]);


    return (
        <main className="main">
            {false ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '80vh' }}>
                    <ScaleLoader
                        color="#BF2626"
                        size={140}
                        loading = {loading}
                    />
                </div>
                :
                <React.Fragment>
                    {isAdmin && 
                        <div className="main__search">
                            <div className="main__filters">
                                <div className="main__add" onClick={() => setWorkspaceOpenModal(true)}>
                                    <img src={addIcon} alt="" />
                                    <p>Ajouter un espace</p>
                                </div>

                                <div className="main__add" onClick={() => openOfficeModal()}>
                                    <img src={addIcon} alt="" />
                                    <p>Ajouter un Bureau</p>
                                </div>
                            </div>
                        </div>
                    }
                    

                    <h1 className="main__heading">
                        Espaces de travail
                    </h1>

                    <div className="main__offices">
                        {buildings?.map(building => (
                            <div className="office" key={building.id}>
                                <h2 className="office__title">
                                    {building.name}
                                    {isAdmin && 
                                        <Pencil size={16} onClick={() => openOfficeModal(building)}/>
                                    }
                                    
                                </h2>
                                <ul className="workspaces">
                                    {workspacesWithCoworker
                                        ?.filter(workspace => workspace.type.name === "Poste fixe" && building.id === workspace.building.id)
                                        .map(workspace => (
                                            <li className="workspace" key={workspace.id}>
                                                <p className="workspace__name">
                                                    {workspace.name}
                                                    {workspace.coworker && ` (${workspace.coworker})`}
                                                </p>
                                                <div>
                                                    <p className={`workspace__status ${workspace.coworker ? 'busy' : 'free'}`}></p>
                                                    {isAdmin && 
                                                        <Pencil size={15} onClick={() => handleEditWorkspace(workspace)}/>
                                                    }
                                                    
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        ))}
                        <div className="office">
                            <h2 className="office__title">Bureaux privatifs</h2>
                            <ul className="workspaces">
                                {workspaces
                                    ?.filter(workspace => workspace.available && workspace.type.name === "Bureau privatif")
                                    ?.map(workspace => (
                                        <li className="workspace" key={workspace.id}>
                                            <p className="workspace__name">{workspace.name}</p>
                                            <p className="workspace__status"></p>
                                        </li>
                                    ))}
                            </ul>
                        </div>

                        <div className="office">
                            <h2 className="office__title">Salles de réunion</h2>
                            <ul className="workspaces">
                                {workspaces
                                    ?.filter(workspace => workspace.available && workspace.type.name === "Salle de réunion")
                                    ?.map(workspace => (
                                        <li className="workspace" key={workspace.id}>
                                            <p className="workspace__name">{workspace.name}</p>
                                            <p className="workspace__status"></p>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            }

            <Modal isOpen={workspaceOpenModal} onClose={() => setWorkspaceOpenModal(false)}>
                <form action="" className="form" onSubmit={handleSubmitWorkspace}>
                    <h2 className="form__title">
                        {isEditMode ? 'Modifier' : 'Ajouter'} un espace de travail
                    </h2>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Nom du lieu de travail"
                        className="form__input"
                        value={workspaceData.name || ""}
                        required
                        onChange={handleChangeWorkspace}
                    />

                    <select
                        name="type"
                        id=""
                        className="form__input"
                        value={workspaceData.type || ""}
                        onChange={handleChangeWorkspace}
                    >
                        <option value="">Types</option>
                        {types?.map((type, index) => (
                            <option key={index} value={`api/workspace_types/${type.id}`}>{type.name}</option>
                        ))}
                    </select>

                    <select
                        name="building"
                        id=""
                        className="form__input"
                        value={workspaceData.building || ""}
                        onChange={handleChangeWorkspace}
                    >
                        <option value="">Bâtiments</option>
                        {buildings?.map(building => (
                            <option key={building.id} value={`api/buildings/${building.id}`}>{building.name}</option>
                        ))}
                    </select>

                    <button type="submit" className="form__submit">
                        Valider
                    </button>
                </form>
            </Modal>

            <Modal isOpen={officeOpenModal} onClose={() => setOfficeOpenModal(false)}>
                <form action="" className="form" onSubmit={handleSubmitOffice}>
                    <h2 className="form__title">
                        {isOfficeEditMode ? 'Modifier' : 'Ajouter'} un Bureau
                    </h2>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Nom du Bureau"
                        className="form__input"
                        value={officeData.name || ""}
                        required
                        onChange={handleChangeOffice}
                    />
                    <button type="submit" className="form__submit">
                        Valider
                    </button>
                </form>
            </Modal>

            {alert.show && (
                <Alert severity={alert.severity} message={alert.message} />
            )}
        </main>
    );
}

export default Workspaces;
