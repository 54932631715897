export function removeEmptyProperties(obj) {
    return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] !== "") {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
}


//Calcule la différence en jours entre 2 dates
export function differenceBetweenDates(date1, date2) {

    const differenceEnMillisecondes = new Date(date1) - new Date(date2);
    const differenceEnJours = Math.ceil(differenceEnMillisecondes / (1000 * 3600 * 24));
    return differenceEnJours
}