
const urlReservations = `${process.env.REACT_APP_API_URL}reservations`;


export const showReservations = async (id) => {
    const token = localStorage.getItem('token'); // Récupérer le token JWT depuis le stockage local
 
    try{
          const response = await fetch(`${urlReservations}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
         });

         if (!response.ok) {
            throw new Error('Erreur lors de la récupération des forfaits');
        }

        const data = await response.json();
        return data['hydra:member']

    }catch (error){
        console.error("Erreur lors de la récupération des forfaits'", error);
        throw error; 
    }
  
}


export const addReservations = async (reservationData) => {
    const token = localStorage.getItem('token'); // Récupérer le token JWT depuis le stockage local
 
    try{
          const response = await fetch(`${urlReservations}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
            body: JSON.stringify(reservationData),
         });

         if (!response.ok) {
            throw new Error('Erreur lors de la récupération des forfaits');
        }

        const data = await response.json();
        return data

    }catch (error){
        console.error("Erreur lors de la récupération des forfaits'", error);
        throw error; 
    }
  
}