// src/components/Coworkers.js

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import { searchCoworkersByName, sortCoworkersByActivity } from "../features/coworkerSlice";
import addIcon from "../assets/images/addIcon.svg";
import { Link } from "react-router-dom";
import MyImage from "../components/MyImage";
import { Eye, Pencil } from "lucide-react";

const Coworkers = () => {
    const dispatch = useDispatch();
    const {coworkers, allCoworkers, status} = useSelector(state => state.coworker);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showAll, setShowAll] = useState(false);


    const coworkersPerPage = 15;

    const handleSortByActivity = (isActive) => {
     
        dispatch(sortCoworkersByActivity(isActive));
    };

    const handleSearch = () => {
        dispatch(searchCoworkersByName(searchInput));
    };

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleShowAll = () => {
        setShowAll(true);
        setCurrentPage(1); // Réinitialisation de la page courante
    };

    const paginate = (pageNumber) => {
        setShowAll(false);
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        handleSearch();
        if (searchInput === ""){
         dispatch(sortCoworkersByActivity("true"))   
        }
    }, [searchInput]);


    const indexOfLastCoworker = currentPage * coworkersPerPage;
    const indexOfFirstCoworker = indexOfLastCoworker - coworkersPerPage;
    const currentCoworkers = showAll ? coworkers : coworkers.slice(indexOfFirstCoworker, indexOfLastCoworker);
    const totalPages = Math.ceil(coworkers.length / coworkersPerPage);

    return (
        
        <React.Fragment>
            <main className="main coworkers">
                {status === "loading" ?
                    <div style={{display:'flex' , justifyContent: 'center', alignItems: "center", height: '80vh'}}>
                        <PuffLoader color="#BF2626" size={140} loading />
                    </div>
                    :
                    <React.Fragment>
                        <div className="main__search">
                            <input 
                                type="text" 
                                className="main__search-input" 
                                value={searchInput} 
                                placeholder="Rechercher..." 
                                onChange={handleInputChange} 
                            />
                            <div className="main__filters">
                                <select 
                                    name="" 
                                    defaultValue={true} 
                                    id="" 
                                    className="main__filter main__filter--hidden" 
                                    onChange={(e) => handleSortByActivity(e.currentTarget.value)}
                                >
                                    <option value="">Tous</option>
                                    <option value="true">Actifs.ves</option>
                                    <option value="false">Non Actifs.ves</option>
                                </select>
                                <Link to="/coworkers/add" className="main__add">
                                    <img src={addIcon} alt="" />
                                </Link>
                            </div>
                        </div>

                        <h1 className="main__heading">
                            <span>{coworkers?.length} </span>Coworkers enregistrés
                        </h1>

                        <div className="dashboard__table">
                            <table className="coworker-list__table">
                                <thead>
                                    <tr>
                                        <th>Profil</th>
                                        <th>Prénom</th>
                                        <th className="job">Job</th>
                                        <th>Email</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentCoworkers.map((coworker, index) => (
                                        <tr className="coworker-list__item" key={index}> 
                                            <td>
                                                <Link to={`${coworker.id}`}>
                                                <MyImage image={{ 
                                                    alt: 'Image du coworker',
                                                    height: '50px',
                                                    src: `https://admin.9coworking.fr/uploads/${coworker.file}`,
                                                    width: '20px',
                                                   
                                                    }} />

{/*                                                
                                                    <img 
                                                        src={`https://admin.9coworking.fr//uploads/${coworker.file}`} 
                                                        alt={`${coworker.surname}`} 
                                                        onError={(e) => e.target.src = errorImage} 
                                                    /> */}
                                                </Link>
                                            </td>
                                            <td className="coworker-list__name">
                                                {coworker.surname}
                                            </td>
                                            <td className="coworker-list__job job">
                                                {coworker.job ? coworker.job : '-'}
                                            </td>
                                            <td className="coworker-list__email">
                                                <a href={`mailto:${coworker.email}`} >{coworker.email}</a>
                                            </td>
                                            <td className="actions coworker-list__actions"> 
                                                <Link to={`${coworker.id}`}>
                                                    <Eye color="#000" size={20} />
                                                </Link>
                                                <Link to={`edit/${coworker.id}`}>
                                                    <Pencil size={20}/>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                  
                        <div className="pagination">
                            {[...Array(totalPages)].map((_, i) => (
                                <button 
                                    key={i} 
                                    onClick={() => paginate(i + 1)} 
                                    className={`pagination__number ${i + 1 === currentPage ? 'active' : ''}`}
                                >
                                    {i + 1}
                                </button>
                            ))}
                            <button 
                            className={`pagination__number ${showAll ? 'active' : ''}`}
                            onClick={handleShowAll}
                            >
                                Tout
                            </button>
                        </div>
                    </React.Fragment>
                }
            </main>
        </React.Fragment>
    );
}

export default Coworkers;
