import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import frLocale from 'date-fns/locale/fr';
import React from 'react'
import { useSelector } from 'react-redux'
import { ChevronDown } from 'lucide-react';
import { format } from 'date-fns';

const Historitical = () => {
  const {attendances} = useSelector(state => state.attendance);

  const getUniqueDates = () => {

    // Utilisez un Set pour obtenir des dates uniques
    const uniqueDatesSet = new Set(attendances.map(attendance => attendance.date));
    const uniqueDatesArray = Array.from(uniqueDatesSet);
    uniqueDatesArray.sort((a, b) => new Date(b) - new Date(a));
    
    return uniqueDatesArray;
};


  const uniqueDates = getUniqueDates();

  return (
    <main className='main'>
          <h2 className='main__heading'>Historique de présences et d'absences</h2>

           {uniqueDates.map(date => {
            return <Accordion >
                      <AccordionSummary
                        expandIcon={<ChevronDown />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ width: '33%', flexShrink: 0, fontFamily: 'Poppins', fontWeight:'500', fontSize: 'clamp(12px, 1vw, 20px)' }}>
                          {format(new Date(date), "dd MMMM yyyy", { locale: frLocale })}
                        </Typography>
                      
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography sx={{ fontFamily: 'Poppins', fontSize: 'clamp(12px, 0.9vw, 15px)' }}>
                          <ul>
                            {attendances
                            .filter(attendance => attendance.date === date)
                            .map(attendance => {
                              return <li key={attendance.id}>{attendance.coworker.surname} - {attendance.workspace.name}({attendance.workspace.building.name}) {!attendance.present && '- Absent'}</li>
                            })}
                          </ul>
                          
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
           })}
          
    </main>
  )
}

export default Historitical
