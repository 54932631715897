import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addAssignment, editAssignment, showAssignments } from "../hooks/useDbCoworker";



export const fetchAssignments= createAsyncThunk('assignments/fetchAssignments', async() => {
    const response = await showAssignments();
    console.log(response)
    return response;
})

export const updateAssignment = createAsyncThunk('assignments/updateAssignment', async({id, data}) => {
    const response = await editAssignment( data, id);
    return response;
})

export const addAssignments = createAsyncThunk('assignments/addAssignment', async({data}) => {
    const response = await addAssignment( data);
    return response;
})

const assignmentSlice = createSlice({
    name : 'assignment',
  
    initialState : {
        assignments : [],
        status : 'idle',
    },
    reducers :  {

    },
    extraReducers :(builder)=>{
        builder
            .addCase(fetchAssignments.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchAssignments.fulfilled, (state, action) => {
                state.assignments = action.payload;
            })
            .addCase(updateAssignment.fulfilled, (state, action) => {
                const index = state.assignments.findIndex(assignment => assignment.id === action.payload.id);
                if (index !== -1) {
                    state.assignments[index] = action.payload;
                }
            })
            .addCase(addAssignments.fulfilled, (state, action) => {
                return {...state, assignments: [...state.assignments , action.payload]}
            })
    }
})

export default assignmentSlice.reducer