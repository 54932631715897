import React from "react";
import { NavLink  } from "react-router-dom";
import logoBlack from "../assets/images/neuf-coworking-logo-red.png"
import closeIcon from "../assets/icons/crossIcon.svg"
import { useSelector } from "react-redux";
import { BriefcaseBusiness, CalendarDays, FileCheck, History, LampDesk, LayoutDashboard, User } from "lucide-react";

const Menu = ({openMenu, toggleMenu}) => {

    const user = useSelector(state => state.user.data)
   
    const isAdmin = user?.roles.includes('ROLE_ADMIN');


    return <div className={`menu ${openMenu ? 'open' : ''}`}>
         <img 
        src={closeIcon} 
        alt="Icone" 
        className="menu__close"
        onClick={toggleMenu}
        />
        <div className="coworker">
            <img src={`https://admin.9coworking.fr/uploads/${user?.file}`} alt="" className="coworker__profil"/>
            <div>
                <h1 className="coworker__name">{user?.surname}</h1>
                <h2 className="coworker__job">{user?.job}</h2>
            </div>
            
        </div>
       
        <ul className="menu__list">

                
            {!isAdmin &&
                
                 <NavLink className="menu__item" to="booking" onClick={toggleMenu}>
                     <FileCheck size={20}/>
                     <p>Forfait</p> 
                 </NavLink>
            
            }
                 
            {isAdmin && 
                <React.Fragment>
                 
                        <NavLink className="menu__item" to="dashboard" onClick={toggleMenu}>
                            <LayoutDashboard size={20}/>
                            <p>Dashboard</p> 
                        </NavLink>
                 

                   
                        <NavLink className="menu__item" to="coworkers" onClick={toggleMenu}>
                            <User size={20}/>
                            <p>Coworkers</p>
                        </NavLink>
                    

                   
                        <NavLink className="menu__item" to="bookings" onClick={toggleMenu}> 
                            <BriefcaseBusiness size={20}/>
                            <p>Tous les forfaits</p>
                        </NavLink>
                   
                </React.Fragment>
            
            }
            

         
                <NavLink className="menu__item" to="reservations" onClick={toggleMenu}>
                    <CalendarDays size={20}/>
                    <p>Réservations</p> 
                </NavLink>
          

            {isAdmin && 
                <React.Fragment>
                 
                        <NavLink className="menu__item" to="attendances" onClick={toggleMenu}>
                            <History size={20}/>
                            <p>Historique</p> 
                        </NavLink>
                
                </React.Fragment>
            
            }

          
                <NavLink className="menu__item" to="workspaces" onClick={toggleMenu}>
                    <LampDesk size={20}/>
                    <p>Espaces de travail</p> 
                </NavLink>
                {/* <NavLink className="menu__item" to="tests" onClick={toggleMenu}>
                    <LampDesk size={20}/>
                    <p>tests</p> 
                </NavLink> */}
          
     
        </ul>
        <img src={logoBlack} alt="Logo du 9 coworking" className="menu__logo"/>
     </div>
}

export default Menu;