import React from "react";
import logoBlack from '../assets/images/logoBlack.svg';
import logoutIcon from '../assets/icons/logoutIcon.svg'
import { Link } from "react-router-dom";
import MyImage from "./MyImage";

const Header = ({toggleMenu, toggleModal}) => {

    return <header className="header">
        <div className="header__left">
            
            <MyImage
                image = {{
                    src: logoBlack,
                    alt : "Logo"
                }}
                cl = "header__logo"
            />
            <p className="header__menu" onClick={toggleMenu}>Menu</p>
        </div>
  
        <div className="header__actions">
            <img src={logoutIcon} alt="Logout" onClick={toggleModal}/>
           
        </div>
    </header>
}

export default Header;