import React, { useState, useEffect, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Menu from './Menu';
import Header from './Header';
import { Outlet } from 'react-router';
import Modal from './Modal';
import { Link } from 'react-router-dom';
import useTokenExpirationChecker from '../hooks/useTokenExpirationChecker';
import { fetchCoworkers } from '../features/coworkerSlice';
import { fetchBookingTypes, fetchBookings, fetchDurationTypes, getAllCoworkers } from '../features/bookingSlice';
import { showUser } from '../features/userSlice';
import { fetchBuildings, fetchWorkspaceTypes, fetchWorkspaces } from '../features/workspaceSlice';
import { fetchReservations } from '../features/reservationSlice';
import { fetchAttendances } from '../features/attendanceSlice';
import { fetchAssignments } from '../features/assignmentSlice';
import { PulseLoader } from 'react-spinners';
import logoWhite from '../assets/images/neuf-coworking-logo-white.png'
import MyImage from './MyImage';


const Layout = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const idUser = '7ce119cd-61d3-4d22-b949-3fed06b0c872';
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);

        const fetchData = async () => {
            try {
                // Priorité haute : données critiques
                await Promise.all([
                    dispatch(showUser(idUser)),
                    dispatch(fetchAssignments()),
                    dispatch(fetchAttendances()),
                    dispatch(fetchWorkspaces()),
                    dispatch(fetchBookings())
                ]);
                setLoading(false);
                
                    
                // Priorité moyenne : données nécessaires pour l'affichage principal
                await Promise.all([
                    dispatch(fetchReservations()),
                    dispatch(getAllCoworkers()),
                    
                ]);

                // Priorité basse : autres données
                
                dispatch(fetchCoworkers());
                
                dispatch(fetchBuildings());
                dispatch(fetchWorkspaceTypes());
                dispatch(fetchBookingTypes());
                dispatch(fetchDurationTypes());
                

               
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false); 
            }
        };

        fetchData();
    }, [dispatch, idUser]);

    const coworkerStatus = useSelector((state) => state.coworker.status);
    const bookingsStatus = useSelector((state) => state.booking.status);
    const workspaceStatus = useSelector((state) => state.workspace.status);
    const reservationStatus = useSelector((state) => state.reservation.status);
    const attendancesStatus = useSelector(state => state.attendance.status);
    const userStatus = useSelector((state) => state.user.status);
    const coworkers = useSelector((state) => state.coworker.coworkers);

    // Renvoyer à la page de login si le token n'est plus valide
    useTokenExpirationChecker();

    const toggleStatusMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const isLoading = loading || userStatus === "succedeed" || coworkerStatus === "succedeed";

    if (isLoading) {
        return (
            <div className="loader">
                <MyImage 
                    image = {{
                        src: logoWhite,
                        alt: 'Logo du 9coworking',
                       
                    }}
                    cl = 'logo'
                    
                />
                <div className="loader__content">
                    <p>Chargement </p>
                    <PulseLoader color='#fff' />
                </div>
            </div>
        );
    }

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <div className="layout">
                    <Menu openMenu={isMenuOpen} toggleMenu={toggleStatusMenu} />
                    <div className="container">
                        <Header toggleMenu={toggleStatusMenu} toggleModal={() => setIsModalOpen(!isModalOpen)} />
                        <>
                            {props.children}
                            <Outlet />
                        </>
                        <footer className="footer">
                            ©2024 9coworking by JKDesign, Marilyne TSENE et beaucoup de ☕
                        </footer>
                    </div>
                </div>
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <div className="layout__logout">
                        <h2 className="title">Etes-vous sûrs de vouloir vous déconnecter ?</h2>
                        <Link to="/" className="validate">
                            Oui
                        </Link>
                    </div>
                </Modal>
            </Suspense>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <div className="layout__logout">
                    <h2 className="title">Etes-vous sûrs de vouloir vous déconnecter ?</h2>
                    <Link to="/" className="validate">
                        Oui
                    </Link>
                </div>
            </Modal>
        </>
    );
};

export default Layout;
