import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import errorImage from '../assets/images/coworker-coworking-la-rochelle.png';

const MyImage = ({ image , cl}) => (
  <div className={cl}>
    <LazyLoadImage

      alt={image.alt}
      height={image.height}
      src={image.src} // use normal <img> attributes as props
      width={image.width} 
      onError={(e) => e.target.src = errorImage}/>
 
  </div>
);

export default MyImage;