// bookinsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showBookings, showDurationType, addBookings as addBookingApi, editBooking as editBookingApi } from '../hooks/useDbBooking';
import { showAttendancesByBooking } from '../hooks/useDbPresence';
import { showBookingTypes } from '../hooks/useDbSettings';

import { getCurrentBooking, showCoworkers } from '../hooks/useDbCoworker';
import { differenceBetweenDates } from '../hooks/useTools';

const today = new Date().toISOString().split('T')[0];

export const fetchBookings = createAsyncThunk('bookings/fetchBookings', async () => {
  const allBookings = await showBookings();
 
  const filteredBookings = allBookings.filter(booking => booking.coworker.active);
  const updatedBookings = await Promise.all(
    filteredBookings.map(async booking => {
    
        const presences = await showAttendancesByBooking(booking.id);

        let joursRestants = null;
        if (booking.duration_type.name === "10 jours"){
          joursRestants =  booking.duration_type.duration - presences.filter(absence => absence.present).length;
       }else {
        joursRestants = differenceBetweenDates(new Date(booking.end_date), new Date().toISOString())
       }
        return { ...booking, remainDays: joursRestants < 0 ? 0 : joursRestants };
    })
  );
  updatedBookings.sort((a, b) => a.coworker.surname.localeCompare(b.coworker.surname));
  return updatedBookings;
});

export const fetchBookingTypes = createAsyncThunk('bookings/fetchBookingTypes', async () => {
  const bookingTypes = await showBookingTypes();
  return bookingTypes;
});

export const fetchDurationTypes = createAsyncThunk('bookings/fetchDurationTypes', async () => {
  const durationTypes = await showDurationType();
  return durationTypes;
});

export const addBooking = createAsyncThunk('bookings/addBooking', async ({ bookingData }) => {
  const response = await addBookingApi(bookingData);
  return response; // Assuming addBookingApi returns updated bookings data
});

export const editBooking = createAsyncThunk('bookings/editBooking', async ({ bookingData, idBooking }) => {
  const response = await editBookingApi(bookingData, parseInt(idBooking, 10));
  return response; // Return the updated booking data
});

export const getAllCoworkers = createAsyncThunk('bookings/coworkers', async () => {
  const allCoworkers = await showCoworkers();
  const coworkersWithNoCurrentBooking = await Promise.all(
    allCoworkers.map(async (coworker) => {
      const currentBooking = await getCurrentBooking(coworker.id, today);
      if (Array.isArray(currentBooking) && currentBooking.length === 0) {
        return coworker;
      }
      return null;
    })
  );

  const sortedCoworkers = coworkersWithNoCurrentBooking
    .filter(coworker => coworker !== null)
    .sort((a, b) => a.surname.localeCompare(b.surname));

  return sortedCoworkers;
});

const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
    bookings: [],
    allBookings: [],
    filteredBookings: [],
    coworkers: [],
    bookingTypes: [],
    durationTypes: [],
    bookingData: {
      name: "", coworker: "", type: "", duration_type: "", start_date: "", end_date: ""
    },
    status: null,
    error: null,
    editingBooking: null,
    isModalOpen: false,
  },
  reducers: {
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
      state.editingBooking = null;
    },
    setEditingBooking: (state, action) => {
      state.editingBooking = action.payload;
    },
    filterBookingsByType: (state, action) => {
      const { idType } = action.payload;
      if (idType.trim() === '') {
        state.bookings = state.filteredBookings;
      } else {
        state.bookings = state.filteredBookings.filter(booking => booking.type.id === parseInt(idType, 10));
      }
    },
    filterBookingsByDuration: (state, action) => {
      const { idBookingDuration } = action.payload;
      if (idBookingDuration.trim() === '') {
        state.bookings = state.filteredBookings;
      } else {
        state.bookings = state.filteredBookings.filter(booking => booking.duration_type.id === parseInt(idBookingDuration, 10));
      }
    },
    filterBookingsByStatus: (state, action) => {
      const status = action.payload.status;
      if (status.trim() === '') {
        state.bookings = state.allBookings;
        state.filteredBookings = state.allBookings;
      } else {
        let filteredBookings;
        const currentDate = new Date().toISOString();

        if (status === 'inProgress') {
          filteredBookings = state.allBookings.filter(booking => {
            const startDate = new Date(booking.start_date).toISOString();
            const endDate = new Date(booking.end_date).toISOString();
            return (startDate <= currentDate && endDate >= currentDate) && (booking.remainDays > 0);
          });
        } else {
          filteredBookings = state.allBookings.filter(booking => {
            const endDate = new Date(booking.end_date).toISOString();
            return endDate < currentDate || booking.remainDays <= 0;
          });
        }

        state.bookings = filteredBookings;
        state.filteredBookings = filteredBookings;
      }
    },
    filterBookingsBySearchTerm: (state, action) => {
      state.bookings = state.filteredBookings.filter(booking =>
        booking.name.toLowerCase().includes(action.payload.toLowerCase()) ||
        booking.coworker.surname.toLowerCase().includes(action.payload.toLowerCase())
      );
    },
    handleChange: (state, action) => {
      const { name, value } = action.payload.target;
      state.bookingData = { ...state.bookingData, [name]: value }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBookings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bookings = action.payload;
        state.allBookings = action.payload;
        state.filteredBookings = action.payload.filter(booking => {
          const currentDate = new Date().toISOString();
          const startDate = new Date(booking.start_date).toISOString();
          const endDate = new Date(booking.end_date).toISOString();
          return (startDate <= currentDate && endDate >= currentDate) && (booking.remainDays > 0);
        });
      })
      .addCase(fetchBookings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchBookingTypes.fulfilled, (state, action) => {
        state.bookingTypes = action.payload;
      })
      .addCase(fetchDurationTypes.fulfilled, (state, action) => {
        state.durationTypes = action.payload;
      })
      .addCase(addBooking.fulfilled, (state, action) => {
        state.bookings = [...state.allBookings, action.payload];
        state.allBookings = [...state.allBookings, action.payload];
      })
      .addCase(editBooking.fulfilled, (state, action) => {
        const updatedBooking = action.payload;
        state.bookings = state.bookings.map(booking =>
          booking.id === updatedBooking.id ? {...updatedBooking, remainDays : booking.remainDays} : booking
        );
        state.allBookings = state.allBookings.map(booking =>
          booking.id === updatedBooking.id ? updatedBooking : booking
        );
      })
      .addCase(getAllCoworkers.fulfilled, (state, action) => {
        state.coworkers = action.payload;
      });
  },
});

export const {
  openModal,
  closeModal,
  setEditingBooking,
  filterBookingsByType,
  filterBookingsByDuration,
  filterBookingsByStatus,
  filterBookingsBySearchTerm,
  filteredBookings,
} = bookingSlice.actions;

export default bookingSlice.reducer;
