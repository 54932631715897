import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { Link } from "react-router-dom";
import Modal from "../components/Modal";
import addIcon from "../assets/images/addIcon.svg";

import { addBooking, 
         editBooking, 
         filterBookingsByDuration, 
         filterBookingsByStatus, 
         filterBookingsByType, 
         filterBookingsBySearchTerm,
         setEditingBooking } from "../features/bookingSlice";
import MyImage from "../components/MyImage";
import { Pencil } from "lucide-react";

const Bookings = () => {
    const dispatch = useDispatch();
  
    const [isForfaitModalOpen, setForfaitModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [showAll, setShowAll] = useState(false);
    const [operationSuccess, setOperationSuccess] = useState();
    const [isLoading, setLoading] = useState(false); // State for loading indicator
    const bookingsPerPage = 10;

    const { bookings,
            coworkers: bookingCowokers, 
            bookingTypes, 
            durationTypes,  
            editingBooking 
        } = useSelector(state => state.booking);

    const {coworkers} = useSelector(state => state.coworker)

    const [bookingData, setBookingData] = useState({
        id: null,
        name: "",
        coworker: "",
        type: "",
        duration_type: "",
        start_date: "",
        end_date: ""
    });

    useEffect(() => {
        dispatch(filterBookingsByStatus({status: 'inProgress'}));
    }, [dispatch]);

    const openForfaitModal = (booking = null) => {
        if (booking) {
            setBookingData({
                id: booking.id,
                name: booking.name,
                coworker: `/api/coworkers/${booking.coworker.id}`,
                type: `/api/booking_types/${booking.type.id}`,
                duration_type: `/api/booking_duration_types/${booking.duration_type.id}`,
                start_date: booking.start_date.split('T')[0],
                end_date: booking.end_date.split('T')[0],
            });
            dispatch(setEditingBooking(booking));
        } else {
            setBookingData({
                id: null,
                name: "",
                coworker: "",
                type: "",
                duration_type: "",
                start_date: "",
                end_date: ""
            });
            dispatch(setEditingBooking(null));
        }
        setForfaitModalOpen(true);
    };

    const closeForfaitModal = () => {
        setForfaitModalOpen(false);
    };

    const handleAddOrEditBooking = async (e) => {
        e.preventDefault();
        console.log(bookingData)
        setLoading(true); // Activate loading indicator
        try {
            if (editingBooking) {
                dispatch(editBooking({ bookingData, idBooking: bookingData.id }));
            } else {
                const { id, ...rest } = bookingData;
                dispatch(addBooking({ bookingData: rest }));
            }
            setOperationSuccess(true);
            closeForfaitModal();
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'ajout ou modification du forfait :", error);
        } finally {
            setLoading(false); // Deactivate loading indicator
        }
    };

    const handleChangeBooking = (e) => {
        const { name, value } = e.target;
        setBookingData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        dispatch(filterBookingsBySearchTerm(e.target.value))
    };

    const indexOfLastBooking = currentPage * bookingsPerPage;
    const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;
    const currentBookings = showAll && bookings ? bookings : bookings.slice(indexOfFirstBooking, indexOfLastBooking);
    const totalPages = Math.ceil(bookings.length / bookingsPerPage);

    const handleShowAll = () => {
        setShowAll(true);
        setCurrentPage(1);
    };

    const paginate = (pageNumber) => {
        setShowAll(false);
        setCurrentPage(pageNumber);
    };

    return (
        <main className="main">
            <React.Fragment>
                <div className="main__search">
                    <input
                        className="main__search-input"
                        type="text"
                        placeholder="Rechercher..."
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    <div className="main__filters">
                        <select 
                            className="main__filter main__filter--hidden" 
                            name="state" 
                            id="state"
                            onChange={(e)=>dispatch(filterBookingsByStatus({status : e.target.value}))}
                        >
                            <option value="">Etat</option>
                            <option value="inProgress" selected>En cours</option>
                            <option value="completed">Terminés</option>
                        </select>
                        <select 
                            className="main__filter main__filter--hidden" 
                            name="type" 
                            id="type" 
                            onChange={(e)=>dispatch(filterBookingsByType({idType : e.target.value}))}
                        >
                            <option value="">Tous les types</option>
                            {bookingTypes.map(type => (
                                <option value={type.id} key={type.id}>{type.name}</option>
                            ))}
                        </select>
                        <select 
                            className="main__filter main__filter--hidden" 
                            name="time" 
                            id="time" 
                            onChange={(e)=>dispatch(filterBookingsByDuration({idBookingDuration : e.target.value}))}
                        >
                            <option value="">Temps</option>
                            {durationTypes.map(type => (
                                <option value={type.id} key={type.id}>{type.name}</option>
                            ))}
                        </select>
                        <p className="main__add" onClick={()=>openForfaitModal(null)}>
                            <img src={addIcon} alt="Ajouter" />
                        </p>
                    </div>
                </div>
                <h1 className="main__heading">{bookings.length} forfaits enregistrés</h1>
                <div className="dashboard__table">
                    <table className="main__table">
                        <thead className="main__table-head">
                            <tr>
                                <th>Profil</th>
                                <th>Prénom</th>
                                <th>Forfait</th>
                                <th className="type">Type</th>
                                <th className="start_date">Date de début</th>
                                <th>Jours restants</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="main__table-body">
                            {currentBookings.map((booking, index) => (
                                <tr key={booking.id}>
                                    <td>
                                        <Link to={`/coworkers/${booking.coworker.id}`}>
                                            <MyImage image={{ 
                                                alt: 'Image du coworker',
                                                height: '60px',
                                                src: `https://admin.9coworking.fr/uploads/${booking.coworker.file}`,
                                                width: '20px',
                                            }} />
                                        </Link>
                                    </td>
                                    <td>{booking.coworker.surname}</td>
                                    <td>{booking.name}</td>
                                    <td className="type">{booking.type.name}</td>
                                    <td className="start_date">{format(new Date(booking.start_date), "dd MMMM yyyy", { locale: frLocale })}</td>
                                    <td>{booking.remainDays}</td>
                                    <td className="actions">
                                        <Pencil size={18} onClick={() => openForfaitModal(booking)}/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="pagination">
                    {[...Array(totalPages)].map((_, i) => (
                        <button 
                            key={i} 
                            onClick={() => paginate(i + 1)} 
                            className={`pagination__number ${i + 1 === currentPage ? 'active' : ''}`}
                        >
                            {i + 1}
                        </button>
                    ))}
                    <button 
                        className={`pagination__number ${showAll ? 'active' : ''}`}
                        onClick={handleShowAll}
                    >
                        Tout
                    </button>
                </div>
            </React.Fragment>
        
            <Modal isOpen={isForfaitModalOpen} onClose={closeForfaitModal}>
                <form action="" className="form" onSubmit={handleAddOrEditBooking}>
                    <h2 className="form__title">{editingBooking ? "Modifier le forfait" : "Ajouter un forfait"}</h2>
                    <input 
                        type="text" 
                        name="name" 
                        placeholder="Entrez le nom" 
                        value={bookingData.name} 
                        className="form__input" 
                        onChange={handleChangeBooking} 
                    />
                    <select 
                        name="coworker" 
                        id="coworker" 
                        className="form__input" 
                        defaultValue={bookingData.coworker}
                        onChange={handleChangeBooking}
                    >
                        <option value="" hidden disabled>Coworker</option>
                        {coworkers.map(coworker => (
                            <option key={coworker.id} value={`/api/coworkers/${coworker.id}`}>{coworker.surname}</option>
                        ))}
                    </select>
                    <select 
                        name="type" 
                        id="type" 
                        className="form__input" 
                        value={bookingData.type} 
                        onChange={handleChangeBooking}
                    >
                        <option value="" hidden selected disabled>Type</option>
                        {bookingTypes.map(type => (
                            <option key={type.id} value={`/api/booking_types/${type.id}`}>{type.name}</option>
                        ))}
                    </select>
                    <select 
                        name="duration_type" 
                        id="duration_type" 
                        className="form__input" 
                        defaultValue={bookingData.duration_type} 
                        onChange={handleChangeBooking}
                    >
                        <option value="" hidden disabled>Type de durée</option>
                        {durationTypes.map(type => (
                            <option key={type.id} value={`/api/booking_duration_types/${type.id}`}>{type.name}</option>
                        ))}
                    </select>
                    <input 
                        type="date" 
                        name="start_date" 
                        value={bookingData.start_date} 
                        className="form__input" 
                        placeholder="Choisir une date" 
                        onChange={handleChangeBooking} 
                    />
                    <input 
                        type="date" 
                        name="end_date" 
                        value={bookingData.end_date} 
                        className="form__input" 
                        placeholder="Choisir une date" 
                        onChange={handleChangeBooking} 
                    />
                    <button type="submit" className="form__submit">Valider</button>
                </form>
            </Modal>
        </main>
    );
}

export default Bookings;
