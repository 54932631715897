// src/features/workspace/workspaceSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showWorkspaces, showBuildings, showWorkspaceTypes, addWorkspaces, updateWorkspaces, addBuilding, updateBuilding } from '../hooks/useDbWorkspace';
import coworkerSlice from './coworkerSlice';

// Thunk to fetch all workspaces
export const fetchWorkspaces = createAsyncThunk('workspace/fetchWorkspaces', async (_, thunkAPI) => {

  const allWorkspaces = await showWorkspaces();

  return allWorkspaces;
});

// Thunk to fetch all buildings
export const fetchBuildings = createAsyncThunk('workspace/fetchBuildings', async () => {
  const buildings = await showBuildings();
  return buildings;
});

// Thunk to fetch all workspace types
export const fetchWorkspaceTypes = createAsyncThunk('workspace/fetchWorkspaceTypes', async () => {
  const workspaceTypes = await showWorkspaceTypes();
  return workspaceTypes;
});

// Thunk to add a new workspace
export const addWorkspace = createAsyncThunk('workspace/addWorkspace', async ({ data }) => {
   const response = await addWorkspaces(data);
  return response;
});

// Thunk to update an existing workspace
export const updateWorkspace = createAsyncThunk('workspace/updateWorkspace', async ({ data, id }) => {
  const response = await updateWorkspaces(id, data);
  return response;
});

export const addBuild = createAsyncThunk('workspace/addBuilding', async ({ data }) => {
   const response = await addBuilding(data);
  return response;
});

export const updateBuild = createAsyncThunk('workspace/updateBuilding', async ({ data, id }) => {
  const response = await updateBuilding(data, id);
  return response;
});

// Redux slice to manage workspaces, buildings, and workspace types
const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: {
    workspaces: [],
    freeWorkspaces : [],
    buildings: [],
    types: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    getFreeWorkspaces : (state, action) => {
      const {assignments, workspaces , attendances} =  action.payload

      const today = new Date().toISOString().split('T')[0];

      const workspacesWithCoworkers = workspaces.map(workspace => {
       
          const matchingAttendance = attendances.some(attendance => {
          
              return attendance.workspace.id === workspace.id && 
              attendance.present && 
              attendance.booking.duration_type.duration === 10 && 
              attendance.date.split('T')[0] === today}
          );
       
          return {
              ...workspace,
              free: !matchingAttendance
          };
      });

      const workspacesOfAssignments = workspacesWithCoworkers.map(workspace => {
          const matchingAssignment = assignments.find(a => a.workspace.id === workspace.id);

          // Check if there is an absence for the current day in the attendances
          const isAbsent = attendances.some(attendance => 
              attendance.workspace.id === workspace.id && 
              !attendance.present && 
              attendance.date.split('T')[0] === today
          );

          // If there's a matching assignment and there is an absence, mark the workspace as free
          return {
              ...workspace,
              free: workspace.free && (isAbsent || !matchingAssignment)
          };
      });
    
      
      state.freeWorkspaces = workspacesOfAssignments
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkspaces.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorkspaces.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.workspaces = action.payload;
      })
      .addCase(fetchWorkspaces.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchBuildings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBuildings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.buildings = action.payload;
      })
      .addCase(fetchBuildings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchWorkspaceTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorkspaceTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.types = action.payload;
      })
      .addCase(fetchWorkspaceTypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addWorkspace.fulfilled, (state, action) => {
     
        state.workspaces = [...state.workspaces, action.payload];
    
     })    
     .addCase(updateWorkspace.fulfilled, (state, action) => {
      const index = state.workspaces.findIndex(workspace => workspace.id === action.payload.id);
      if (index !== -1) {
          state.workspaces[index] = action.payload;
      }
  })
      .addCase(addBuild.fulfilled, (state, action) => {
        state.buildings = [...state.buildings, action.payload];
      })
      .addCase(updateBuild.fulfilled, (state, action) => {
        const index = state.buildings.findIndex(building => building.id === action.payload.id);
        if (index !== -1) {
            state.buildings[index] = action.payload;
        }
      });
  },
});

export const {getFreeWorkspaces} = workspaceSlice.actions

export default workspaceSlice.reducer;
