const urlWorkspaces = `${process.env.REACT_APP_API_URL}workspaces`; 
const urlWorkspaceTypes = `${process.env.REACT_APP_API_URL}workspace_types`; 
const urlBuildings = `${process.env.REACT_APP_API_URL}buildings`; 
const token = localStorage.getItem('token');

export const showWorkspaces = async () => {
    const token = localStorage.getItem('token'); // Récupérer le token JWT depuis le stockage local
    let allWorkspaces = [];
    const baseUrl = 'https://admin.9coworking.fr';
    try{
          let nextPageUrl = urlWorkspaces;

          while(nextPageUrl){
            
            const response = await fetch(`${nextPageUrl}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/ld+json',
                },
                
             });
    
             if (!response.ok) {
                throw new Error('Erreur lors de la récupération des lieux de travail');
            }
    
            const data = await response.json();
    
               // Ajouter les membres de la page actuelle à l'ensemble des membres
               allWorkspaces = allWorkspaces.concat(data['hydra:member']);
    
               // Vérifier s'il y a une page suivante dans hydra:view
               const view = data['hydra:view'];
           
               if (view && view['hydra:next']) {
                   nextPageUrl = `${baseUrl}${view['hydra:next']}`;
               } else {
                   nextPageUrl = null;
               }
          }
 

        return allWorkspaces;

    }catch (error){
        console.error("Erreur lors de la récupération des lieux de travail'", error);
        throw error; 
    }
  
}

export const showBuildings = async () => {
    const token = localStorage.getItem('token'); // Récupérer le token JWT depuis le stockage local
 
    try{
          const response = await fetch(`${urlBuildings}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
            
         });

         if (!response.ok) {
            throw new Error('Erreur lors de la récupération');
        }

        const data = await response.json();
        return data['hydra:member']

    }catch (error){
        console.error("Erreur lors de la récupération'", error);
        throw error; 
    }
  
}

export const showWorkspaceTypes = async (id) => {
    const token = localStorage.getItem('token'); // Récupérer le token JWT depuis le stockage local
 
    try{
          const response = await fetch(`${urlWorkspaceTypes}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
            
         });

         if (!response.ok) {
            throw new Error('Erreur lors de la récupération');
        }

        const data = await response.json();
        return data['hydra:member']

    }catch (error){
        console.error("Erreur lors de la récupération'", error);
        throw error; 
    }
  
}

export const showWorkspace = async (id) => {
    const token = localStorage.getItem('token'); // Récupérer le token JWT depuis le stockage local
 
    try{
          const response = await fetch(`${urlWorkspaces}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
         });

         if (!response.ok) {
            throw new Error('Erreur lors de la récupération des lieux de travail');
        }

        const data = await response.json();
        return data['hydra:member']

    }catch (error){
        console.error("Erreur lors de la récupération des lieux de travail'", error);
        throw error; 
    }
  
}

export const addWorkspaces = async(workspaceData) => {
    const token = localStorage.getItem('token');
    try{
        const response = await fetch(`${urlWorkspaces}`, {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/ld+json',
          },
          body: JSON.stringify(workspaceData),
       });

       if (!response.ok) {
          throw new Error("Erreur lors de l'ajout du lieu de travail");
      }

      const data = await response.json();
      return data

  }catch (error){
      console.error("Erreur lors de l'ajout du lieu de travail", error);
      throw error; 
  }
}

export const updateWorkspaces = async(idWorkspace, workspaceData) => {
    
    try{
        const response = await fetch(`${urlWorkspaces}/${idWorkspace}`, {
          method: 'PATCH',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/merge-patch+json',
          },
          body: JSON.stringify(workspaceData),
       });

       if (!response.ok) {
          throw new Error("Erreur lors de la modification du lieu de travail");
      }

      const data = await response.json();
      return data

  }catch (error){
      console.error("Erreur lors de la modification du lieu de travail", error);
      throw error; 
  }
}

export const addBuilding = async(buildingData) => {
    try {
        const response = await fetch(`${urlBuildings}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
            body: JSON.stringify(buildingData),
        });

        if (!response.ok) {
            throw new Error("Erreur lors de l'ajout du lieu de travail");
        }

        const data = await response.json();
        return data;  // Assurez-vous de retourner les données reçues du serveur

    } catch (error) {
        console.error("Erreur lors de l'ajout du lieu de travail", error);
        throw error;
    }
}

export const updateBuilding = async(buildingData, id) => {
    const token = localStorage.getItem('token');
    console.log(id)
    try{
        const response = await fetch(`${urlBuildings}/${id}`, {
          method: 'PATCH',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/merge-patch+json',
          },
          body: JSON.stringify(buildingData),
       });

       if (!response.ok) {
          throw new Error("Erreur lors de la modification du lieu de travail");
      }

      const data = await response.json();
      return data

  }catch (error){
      console.error("Erreur lors de la modification du lieu de travail", error);
      throw error; 
  }
}