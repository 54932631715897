import React, { useCallback, useEffect, useState } from "react"
import { ScaleLoader } from "react-spinners";
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { addAttendance, showAttendancesByBooking, updateAttendance } from "../hooks/useDbPresence";
import Alert from "../components/Alert";
import { Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoworker, fetchCurrentBooking } from "../features/coworkerSlice";

import {   Pencil } from "lucide-react";
import { getFreeWorkspaces } from "../features/workspaceSlice";


const Booking = () => {

    const dispatch = useDispatch();


    const {attendances} = useSelector(state => state.attendance);
    const {currentBooking} = useSelector(state => state.coworker);

    const {workspaces, freeWorkspaces} = useSelector(state => state.workspace);
    const {assignments} =  useSelector(state => state.assignment);
    const {data} = useSelector(state => state.user)
 

    const idCoworker = data?.id;

   
    const [days, setDays] = useState([]);

    const [setIsworkspaceModalOpen] = useState(false);
    const [alert, setAlert] = useState({ show: false, severity: "", message: "" });
    const [loading, setLoading] = useState(false)

    const [attendance, setAttendance] = useState({
        date: null,
        coworker: `api/coworkers/${idCoworker}`,
        booking: null,
        workspace : null,
        present: true
    });
 

   

    const showAlert = useCallback((severity, message) => {
        setAlert({ show: true, severity, message });
     
    }, []);

    // Date d'aujourd'hui
    const today = new Date().toISOString().split('T')[0];

    // Récupérer les présences ou les absences d'un coworker pour le jour courant
    const showAbsences = useCallback(async () => {
        if (currentBooking) {
            const allAbsences = await showAttendancesByBooking(currentBooking?.id);
            
            const filteredAbsences = allAbsences.filter(absence => currentBooking?.duration_type?.name === 'Mois' ? !absence.present : absence.present);
            const sortedAbsences = filteredAbsences.sort((a, b) => new Date(a.date) - new Date(b.date));
            setDays(sortedAbsences);
        } else {
            setDays([]);
        }
    },[currentBooking]);

    //Récupérer les données du coworker et du forfait courant
    const getAllData = useCallback(async () => {
        dispatch(fetchCoworker({ id: idCoworker }));
        dispatch(fetchCurrentBooking({ id: idCoworker, date: today , attendances : attendances}));

        if (currentBooking?.duration_type?.name === 'Mois'){
            const currentAttendanceData = {...attendance, workspace : `/api/workspaces/${currentBooking?.workspace?.id}`}
            setAttendance(currentAttendanceData )
        }
    }, [idCoworker, currentBooking?.duration_type?.name]);

    useEffect(() => {
        getAllData();
       
        const timer = setTimeout(() => {
            setLoading(true)
        }, 2000);
        setLoading(false)
        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
   
    }, [getAllData]);

    useEffect(() => {
        if (currentBooking){
            showAbsences(); 
        }
      
    }, [currentBooking, showAbsences]);
    

    //Initialiser le state attendance en y ajoutant l'IRI de l'id ?
    useEffect(() => {
        if (currentBooking) {
            setAttendance(prevAttendance => ({
                ...prevAttendance,
                booking: `api/bookings/${currentBooking.id}`
            }));
        }
      
    }, [currentBooking]);

 

    //Gestion des actions faites sur les présences/absences des coworkers
    const handleValidation = async(e) => {

        e.preventDefault();
  
        try {
            const existingAttendances = await showAttendancesByBooking(currentBooking.id);
            const existingAttendance = existingAttendances.find(a => 
                {
                    return a.date.split('T')[0] === attendance.date.split('T')[0]
                }
            );
    
            if (existingAttendance) {
               
                if (existingAttendance.present === attendance.present) {
                    showAlert("warning", "L'attendance pour cette date existe déjà avec le même état.");
                } else {
                    
                    await updateAttendance(existingAttendance.id, { present: attendance.present });
                     showAlert("success", "L'attendance a été mise à jour.");
                }
            } else {

                await addAttendance(attendance);
                showAlert("success", "L'attendance a été ajoutée.");
            }
    
            await showAbsences(currentBooking); // Mettre à jour la liste des absences après ajout ou mise à jour
        } catch (error) {
            console.error("Erreur lors de la validation de l'attendance :", error);
            showAlert("error", "Erreur lors de la validation de l'attendance.");
        }
        setTimeout(() => {
            setAlert({...alert, show :  false})
          }, "2000");
        dispatch(fetchCurrentBooking({ id: idCoworker, date: today , attendances : attendances}))
    
    };


    const getWorkspaceWithCoworkers = useCallback(() => {
        dispatch(getFreeWorkspaces({assignments : assignments, workspaces : workspaces, attendances : attendances}))
    }, [attendances, workspaces, today, assignments]);

    useEffect(() => {
        getWorkspaceWithCoworkers()
    }, [getWorkspaceWithCoworkers])
  
    

    return (
        <main className="main">
            {!loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '80vh' }}>
                    <ScaleLoader color="#BF2626" size={140} loading />
                </div>
            ) : (
                <React.Fragment>
                   
                    <h1 className="main__heading">Bonjour <span>{data.surname}</span>, bienvenue dans l'administration du <span>9Coworking</span></h1>
                    <section className="coworker__section main__section">
                
           
                        <article className="coworker__currentBooking">
            
                                <h2 className="coworker__title">Forfait en cours</h2>
                                {currentBooking === null || Object.keys(currentBooking).length === 0 ? 
                                    <div className="coworker__message">
                                          <p className="coworker__error">Désolé, vous n'avez  aucun forfait en cours...</p> 
                                        
                                    </div>
                                  
                                    :   
                                    <>
                                        <ul className="coworker__infos">
                                            <li className="coworker__info"><span>Nom du forfait : </span>&nbsp;{currentBooking?.name}</li>
                                            <li className="coworker__info"><span>Type de forfait : </span>&nbsp;{currentBooking?.type.name}</li>
                        
                                            <li className="coworker__info"><span>Date de début : </span>&nbsp;{currentBooking?.start_date ? format(new Date(currentBooking?.start_date), "dd MMMM yyyy", { locale: frLocale }) : 'Non mentionné'}</li>
                                            <li className="coworker__info"><span>Date de fin : </span>&nbsp;{currentBooking?.end_date ? format(new Date(currentBooking?.end_date), "dd MMMM yyyy", { locale: frLocale }) : 'Non mentionné'}</li>
                                            {currentBooking.duration_type.name === 'Mois' && 
                                                    <li className="coworker__info">
                                                        <span>Emplacement : </span>&nbsp;
                                                        <p>&nbsp;
                                                        {currentBooking.workspace
                                                            ? `${currentBooking.workspace.name} - ${currentBooking.workspace.building?.name ?? 'Nom du bâtiment non disponible'}`
                                                            : 'Non mentionné'}
                                                        </p>
                                                        <Pencil size={20} onClick={()=>setIsworkspaceModalOpen(true)}/>
                                                    </li>
                                            }
                                            
                                            <li className="coworker__info"><span>Jours restants : </span>&nbsp;{currentBooking?.remainDays !== null ? currentBooking?.remainDays : 'Non applicable'}</li>
                                        
                                        </ul>
                                        <br /><br /><br /><br />
                                        <table className="main__table">
                                            <thead className="main__table-head">
                                                <tr>
                                                    <th>Jour</th>
                                                    <th>Etat</th> 
                                                </tr>
                                            </thead>
                                            {days?.length > 0 && (
                                                <tbody className="main__table-body">
                                                    {days.map((day, index) => (
                                                        <tr key={index}>
                                                            <td>{format(new Date(day.date), 'dd MMMM yyyy', { locale: frLocale })}</td>
                                                            <td className={`status-cell ${!day.present ? 'red' : 'green'}`}>
                                                                {day.present ? 'Présent.e' : 'Absent.e'} 
                                                        
                                                                
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            )}
                                        </table>
                                       
                                    </>
                                 
                                }
                                           
                        </article>

                        {currentBooking?.remainDays > 0 && 
                            <article>
                                <form className="validation form" onSubmit={handleValidation}>
                                        
                                        <h2 className="coworker__title">Validation de présence ou absence</h2>
                                
                                        <input type="date" 
                                            min={`${currentBooking?.start_date?.split('T')[0]}`}
                                            max={`${currentBooking?.start_end?.split('T')[0]}`}
                                            value={attendance.date}
                                            onChange={(e)=>setAttendance({...attendance, date : e.target.value})}
                                            className="form__input"
                                            required
                                        />
                                
                                        {currentBooking?.duration_type.name === '10 jours' && 
                                            <select 
                                            name="workspace" 
                                            id=""  
                                            onChange={(e) => setAttendance({ ...attendance, workspace: e.target.value })}
                                            className="form__input"
                                            required
                                            >
                                                <option value="" required hidden>Choisir un lieu de travail</option>
                                                {freeWorkspaces
                                                ?.filter(w => !w.coworker && w.type.name === "Poste fixe")
                                                .map(workspace => <option key={workspace.id} value={`/api/workspaces/${workspace.id}`}>
                                                                                { workspace.name} - {workspace.building.name}
                                                                                </option>
                                                        )}
                                            </select>
                                        }
                                        

                                        <div className="validation__attendance">
                                            <p>Absent</p>
                                            <Switch
                                                checked={attendance.present}
                                                onChange={(e) => setAttendance({...attendance, present: e.target.checked})}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                color="error"
                                            />
                                            <p>Présent</p>
                                        </div>

                                    
                                        
                                
                                        <button className="validation__action"  type="submit">
                                            Ajouter
                                        </button>
                                </form>
                            </article>
                            }
                            
                       
                    </section>
                </React.Fragment>
            )}


            {alert.show && (
                <Alert severity={alert.severity} message={alert.message} />
            )}
        </main>
    );
}

export default Booking;
