import React, { useCallback, useEffect, useState } from "react";

import { getProfilUrl} from "../hooks/useDbCoworker";
import {  addBookings } from '../hooks/useDbBooking';
import errorImage from '../assets/images/coworker-coworking-la-rochelle.png';
import { format, getHours, getMinutes } from 'date-fns';
import frLocale from 'date-fns/locale/fr';

import Modal from "../components/Modal";
import { useNavigate } from "react-router";

import {  ScaleLoader } from "react-spinners";
import { differenceBetweenDates } from "../hooks/useTools";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addReservation} from "../features/reservationSlice";
import attendanceSlice, { addAttendances, 
         fetchPresencesOfToday, 
         updateAttendances, 
         updateAttendanceData, 
         replaceData 
        } from "../features/attendanceSlice";
import MyImage from "../components/MyImage";
import { addPresenceForCoworkers, fetchCurrentBooking, markPresenceForCoworker } from "../features/coworkerSlice";
import { Pencil } from "lucide-react";
import { getFreeWorkspaces } from "../features/workspaceSlice";


const Dashboard = () => {


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = localStorage.getItem("token");
    if (!token) {
        navigate("/");
    }

    const [isForfaitModalOpen, setForfaitModalOpen] = useState(false);
    const [isReservationModalOpen, setReservationModalOpen] = useState(false);
    const [presenceModalOpen, setPresenceModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({ show: false, severity: "", message: "" });


    const today = new Date().toISOString().split('T')[0];

    const [editingPresence, setEditingPresence] = useState(null);


    const [reservationData, setReservationData] = useState({
        workspace: "", coworker: "", startDate: "", startTime: "", endTime: ""
    });

    const [bookingData, setBookingData] = useState({
        name: "", coworker: "", type: "", duration_type: "", start_date: "", end_date: ""
    });

    const {reservationsOfToday} = useSelector (state => state.reservation);
    const {birthdaysNext30Days, coworkers} = useSelector (state => state.coworker);
    const {durationTypes, bookingTypes, bookings} = useSelector(state => state.booking);
    const {workspaces, freeWorkspaces} = useSelector(state => state.workspace);
    const {attendances, presencesOfToday, attendanceData} = useSelector(state => state.attendance);
    const {assignments} = useSelector(state => state.assignment);
 
    

    ////Fonctions répondants à l'ouverture ou fermeture des modales
    const openForfaitModal = () => setForfaitModalOpen(true);
    const closeForfaitModal = () => setForfaitModalOpen(false);
    const openReservationModal = () => setReservationModalOpen(true);
    const closeReservationModal = () => setReservationModalOpen(false);


    //Fonction pour montrer l'alert
    const showAlert = (severity, message) => {
        setAlert({ show: true, severity, message });
        setTimeout(() => {
            setAlert({ show: false, severity: "", message: "" });
        }, 3000);
    };

    ////////////////////Fonctions de changement de template de state/////////////

    const handleChangeReservation = (e) => {
        const { name, value } = e.target;
        setReservationData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeBooking = (e) => {
        const { name, value } = e.target;
        setBookingData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    //////////////////////////////////////////////////////////////////////////////

    // Fonction pour ouvrir la modalité de modification et pré-remplir les données de présence
    const openEditPresenceModal = (data) => {
        setEditingPresence(data.id);
        dispatch(replaceData({...attendanceData, coworker : '/api/coworkers/' + data.coworker.id, workspace : '/api/workspaces/' + data.workspace.id}))
        setPresenceModalOpen(true);
    };

    const openAddPresenceModal = () => {
        setEditingPresence(null);
        dispatch(replaceData({
            date: today,
            coworker: null,
            workspace:null,
            booking: null,
            present: true
        }))
        setPresenceModalOpen(true)
    }

    const getAllData = useCallback (async () => {
        setLoading(true);
        
        try {
            if (attendances && assignments && workspaces){
                dispatch(getFreeWorkspaces({assignments : assignments, workspaces : workspaces, attendances : attendances}))
            }
           
            if (bookings.length !== 0 && attendances.length !== 0) {
                setLoading(false)
                dispatch(fetchPresencesOfToday({ bookings, attendances }));
                
            }else{
                setLoading(true)
            }
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
        } finally {
            
        }
    }, [bookings, attendances, workspaces, assignments]);

    
    const handleChangeAttendance = (e) => {
     
        const { name, value } = e.target;
        dispatch(updateAttendanceData({ name, value }));
    };


    useEffect(() => {
        const fetchData = async () => {
            await getAllData(); 
        };
        fetchData();
    }, [getAllData]);

    ////////////////////////Fonctions d'ajout/////////////////////////////

    const handleAddBookings = async (e) => {
        e.preventDefault();
        try {
            await addBookings(bookingData);
            closeForfaitModal();
            showAlert("success", "Forfait ajouté avec succès");
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'ajout du forfait :", error);
            showAlert("error", "Une erreur s'est produite lors de l'ajout du forfait");
        }
    };


    const handleAddReservation = async (e) => {
        e.preventDefault();
        try {
            const formattedDataReservation = {
                coworker: reservationData.coworker,
                workspace: reservationData.workspace,
                start_date: reservationData.startDate + 'T' + reservationData.startTime,
                end_date: reservationData.startDate + 'T' + reservationData.endTime
            };
            dispatch(addReservation({data : formattedDataReservation}));
            closeReservationModal();
            showAlert("success", "Réservation ajoutée avec succès");
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'ajout de la réservation :", error);
            showAlert("error", "Une erreur s'est produite lors de l'ajout de la réservation");
        }
    };


    //Ajout d'une présence pour un nomade
    const handleAddPresence = async (e) => {
        e.preventDefault();
        try {
            const selectedCoworker = e.target.coworker.value;
            const idSelectedCoworker = selectedCoworker.substr(selectedCoworker.lastIndexOf('/') + 1);
            // const bookingId = e.target.bookingId.value;

            if (!editingPresence) {

                // Attendre que le current booking soit chargé
                const currentBookingResult = await dispatch(fetchCurrentBooking({ id: idSelectedCoworker, date: today, attendances })).unwrap();
                dispatch(addAttendances({ data: { ...attendanceData, booking: `api/bookings/${currentBookingResult.id}` } }));
                dispatch(markPresenceForCoworker(idSelectedCoworker));
                setPresenceModalOpen(false);
                // getAllData();
                showAlert("success", "Présence ajoutée avec succès");
            } else {
                await dispatch(updateAttendances({ id: editingPresence, data: { workspace: attendanceData.workspace } }));
                setPresenceModalOpen(false);
            }
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'ajout de la présence :", error);
            showAlert("error", "Une erreur s'est produite lors de l'ajout de la présence");
        }
    };


    return <React.Fragment>
       
                <main className="dashboard">

                    <h1 className="dashboard__title">Dashboard</h1>

                                    
                    <section className="dashboard__content">
                        <article className="reservations">
                                <h2 className="reservations__title">
                                    <span>{reservationsOfToday?.length}</span><br></br>
                                    réservation{reservationsOfToday?.length === 1 ? '' : 's'} de la salle de réunion aujourd'hui
                                </h2>
                                {
                                    reservationsOfToday?.map((reservation, index) => {
                                    return     <div key={`${Date.now()} - ${index}`} className="reservations__item">

                                                    <p>{getHours(reservation.start_date || reservation.start.dateTime)}h{getMinutes(reservation.start_date || reservation.start.dateTime)} - 
                                                        {getHours(reservation.end_date || reservation.end.dateTime)}h{getMinutes(reservation.end_date || reservation.end.dateTime)}
                                                    </p>
                                                    <div>
                                                        <p>{reservation.coworker?.surname || reservation.summary}</p>
                                                        <p>{reservation.workspace?.name || ''}</p>
                                                    </div>

                                                </div>
                                    })
                                }
                        

                        </article>

                        <article className="actions">
                            <p className="actions__item actions__item--hidden" onClick={openForfaitModal}>Ajout d'un forfait</p>
                            <p className="actions__item actions__item--hidden" onClick={openReservationModal}>Ajouter une réservation</p>
                            <p className="actions__item" onClick={openAddPresenceModal}>Ajouter une présence</p>
                        </article>

                    </section>
                    <section>
                        <article className="birthdays">
                            <h2 className="birthdays__title">Anniversaires à venir</h2>
                            <ul className="birthdays__list"> 
                                {birthdaysNext30Days?.length > 0 ? (
                                    birthdaysNext30Days
                                    ?.map(coworker => (
                                        <li key={coworker.id} className="coworker">
                                            <img 
                                            src={getProfilUrl(coworker.file)} 
                                            alt={coworker.name} 
                                            className="coworker__profil" 
                                            onError={(e) => e.target.src = errorImage} 
                                            />
                                            <div className="coworker__infos">
                                                <p>{coworker.surname}</p>
                                                <p>{format(new Date(coworker.birthday), "dd MMMM", { locale: frLocale })}</p>
                                                <p>{Math.floor(differenceBetweenDates(new Date().toISOString(), coworker.birthday)/365.25)} ans</p>
                                            </div>
                                        
                                        </li>
                                    ))
                                ) : (
                                    <li>Aucun anniversaire à venir dans les 30 prochains jours.</li>
                                )}
                            </ul>
                        </article>
    
                        
                    </section>
                    <div className="dashboard__table">
                    
                        <table>
                            <thead>
                                <tr>
                                    <th className="profil">Profil</th>
                                    <th>Prénom</th>
                                    <th>Forfait</th>
                                    <th className="start_date">Date de début</th>
                                    <th>Jours restants</th>
                                    <th className="workspace">Emplacement</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {loading ? (
                                <tbody>
                                    <tr>
                                        <td colSpan="7" style={{ textAlign: 'center', height: '20vh' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                <ScaleLoader
                                                    color="#BF2626"
                                                    size={100}
                                                    loading
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {presencesOfToday.length > 0 ? (
                                        presencesOfToday.map((b, index) => (
                                            <tr key={`${Date.now()} - ${index}`}>
                                                <td className="profil">
                                                    <Link to={`/coworkers/${b.coworker.id}`}>
                                                        <MyImage image={{
                                                            src : getProfilUrl(b.coworker.file),
                                                            alt : b.coworker.surname,
                                                            width : "10rem"
                                                            }} 
                                                        />
                                                
                                                    </Link>
                                                </td>
                                                <td>{b.coworker.surname}</td>
                                                <td>{b.booking.name}</td>
                                                <td className="start_date">
                                                    {format(new Date(b.booking.start_date), "dd MMMM yyyy", { locale: frLocale })}
                                                </td>
                                                <td>{b.remainDays}</td>
                                                <td>{b.workspace.building.name} - {b.workspace.name} </td>
                                                <td className="actions">
                                                    <Pencil size={20} onClick={() => openEditPresenceModal(b)}/>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7" style={{ textAlign: 'center' }}>Aucune présence trouvée pour aujourd'hui</td>
                                        </tr>
                                    )}
                                </tbody>
                            )}
                        </table>
                        
                    </div>
                               
                </main>

                <Modal isOpen={isForfaitModalOpen} onClose={closeForfaitModal}>
                
                    <form action="" className="form" onSubmit={handleAddBookings}>
                        <h2 className="form__title">Ajouter un forfait</h2>
                        <input 
                            type="text" 
                            name="name" 
                            placeholder="Entrez le nom" 
                            value={bookingData.name} 
                            className="form__input" 
                            onChange={handleChangeBooking}
                        />

                        <select 
                            name="coworker" 
                            id="coworker" 
                            className="form__input" 
                            value={bookingData.coworker} 
                            onChange={handleChangeBooking}
                        >
                            <option value="" disabled selected hidden>Choisir un coworker...</option>
                            {
                                coworkers
                                ?.filter(coworker => !coworker.hasCurrentBooking)
                                .map((coworker, index) => {
                                    return <option key={`${Date.now()} - ${index}`} value={`/api/coworkers/` + coworker.id}>{coworker.surname}</option>
                                })
                                
                            }
                        </select>

                        <select 
                            name="type" 
                            id="type" 
                            className="form__input" 
                            value={bookingData.type} 
                            onChange={handleChangeBooking}
                        >
                            <option value="" disabled selected hidden>Choisir le type...</option>
                            {
                                bookingTypes?.map((type, index) => {
                                    return <option key={`${Date.now()} - ${index}`} value={`/api/booking_types/` + type.id}>{type.name}</option>
                                })
                            }
                        </select>

                        <select 
                            name="duration_type" 
                            id="duration_type" 
                            className="form__input" 
                            value={bookingData.duration_type} 
                            onChange={handleChangeBooking}
                        >
                            <option value=""  disabled selected hidden>Choisir le type de durée...</option>
                            {
                                durationTypes?.map((type, index) => {
                                    return <option key={`${Date.now()} - ${index}`} value={`/api/booking_duration_types/` + type.id}>{type.name}</option>
                                })
                            }
                        </select>

                       
                        <input 
                            type="date" 
                            name="start_date" 
                            required 
                            value={bookingData.start_date} 
                            className="form__input" 
                            placeholder="choisir une date" 
                            onChange={handleChangeBooking}
                        />

                        <input 
                            type="date" 
                            name="end_date" 
                            required 
                            value={bookingData.end_date} 
                            className="form__input" 
                            placeholder="choisir une date" 
                            onChange={handleChangeBooking}
                        />

                        <button type="submit" className="form__submit">
                                Valider
                        </button>
                    </form>
                </Modal>

                <Modal isOpen={isReservationModalOpen} onClose={closeReservationModal}>
                  
                    <form action="" className="form" onSubmit={handleAddReservation}>

                            <h2 className="form__title">Ajouter une réservation</h2>
                            <select 
                                name="workspace" 
                                id="" 
                                className="form__input" 
                                value={reservationData.workspace} 
                                onChange={handleChangeReservation}
                                required
                            >
                                <option value="" disabled selected hidden>Choisir la salle de réunion...</option>
                                {
                                    workspaces?.filter((workspace) => workspace.type.name === 'Salle de réunion').map((workspace, index) => {
                                        return <option value={`/api/workspaces/${workspace.id}`} key={`${Date.now()} - ${index}`}>{workspace.name}</option>
                                    })
                                }

                            </select>

                            <select 
                                name="coworker" 
                                id="" 
                                className="form__input" 
                                value={reservationData.coworker} 
                                onChange={handleChangeReservation}
                                required
                            >
                                <option value="" disabled selected hidden>Coworker</option>
                                {
                                    bookings?.map((b, index) => {
                                        return <option value={`/api/coworkers/${b.coworker.id}`} key={`${Date.now()} - ${index}`}>{b.coworker.surname}</option>
                                    })
                                }
                            </select>

                            <input 
                                type="date" 
                                name="startDate" 
                                className="form__input" 
                                placeholder="choisir une date"
                                onChange={handleChangeReservation}
                                required
                            />

                            <div className="form__time">
                                <div>
                                    <label htmlFor="" >Heure de début</label><br></br>
                                    <input 
                                        aria-label="Time" 
                                        name="startTime" 
                                        type="time" 
                                        className="form__input" 
                                        onChange={handleChangeReservation}
                                        required
                                    />
                                </div>

                                <div>
                                    <label htmlFor="" >Heure de Fin</label><br></br>
                                    <input 
                                        aria-label="Time" 
                                        type="time" 
                                        name="endTime" 
                                        className="form__input" 
                                        onChange={handleChangeReservation}
                                        required
                                    />
                                </div>
                            </div>
                         
                          

                            <button type="submit" className="form__submit">
                                Valider
                            </button>
                    </form>
                </Modal>

        
                <Modal isOpen={presenceModalOpen} onClose={() => setPresenceModalOpen(false)}>
                    <form action="" className="form" onSubmit={handleAddPresence}>
                        <h2 className="form__title">{editingPresence ? 'Modification' : 'Ajout'} d'une présence</h2>
                       
                            <>
                                <select 
                                    name="coworker" 
                                    id="coworker" 
                                    className="form__input" 
                                    onChange={handleChangeAttendance}
                                    value={attendanceData.coworker}
                                    required
                                >
                                    <option value="" hidden>Sélectionner un coworker</option>
                                {
                                    coworkers?.filter( coworker => coworker.hasCurrentBooking && coworker.isNomade && !coworker.isPresent)
                                              .map((coworker, index) => (
                                                <option key={coworker.id} value={`/api/coworkers/${coworker.id}`}>
                                                    {coworker.surname}
                                                </option>
                                    ))
                                }
                                </select>
                                <input type="hidden" name="bookingId" value={attendanceData.bookingId} />
                            </>
                  

                        <select
                            name="workspace"
                            id=""
                            className="form__input"
                            required
                            value={attendanceData.workspace}
                            onChange={handleChangeAttendance}
                        >
                            <option value="" hidden>Poste ou salle</option>
                            {( freeWorkspaces)?.filter((workspace) => workspace.available && workspace.type.name === "Poste fixe" && workspace.free).map((workspace, index) => {
                                return <option value={`/api/workspaces/${workspace.id}`} key={`${Date.now()} - ${index}`}>{workspace.name} - {workspace.building.name}</option>;
                            })}
                        </select>

                        <button type="submit" className="form__submit">
                            Valider
                        </button>
                    </form>
                </Modal>


                {alert.show && (
                    <Alert severity={alert.severity} message={alert.message} />
                )}
            </React.Fragment>
    
}

export default Dashboard;

