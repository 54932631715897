import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addCoworker, editCoworker, getCurrentBooking, showAssignmentsByBooking, showCoworker, showCoworkers } from '../hooks/useDbCoworker';
import { showAttendancesByBooking } from '../hooks/useDbPresence';
import { differenceBetweenDates } from '../hooks/useTools';
import { addDays, isBefore, isAfter, isEqual } from 'date-fns';

// Fonction pour calculer les anniversaires des 30 prochains jours
const calculateBirthdaysNext30Days = (coworkers) => {
  const today = new Date();
  const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const next30Days = addDays(startOfToday, 30);

  const coworkersSortByBirthdays = coworkers
    .filter(coworker => {
      if (!coworker.birthday) {
        return false;
      }

      const birthDate = new Date(coworker.birthday);
      if (isNaN(birthDate)) {
        return false;
      }

      const thisYearBirthday = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());

      if (isBefore(thisYearBirthday, startOfToday)) {
        thisYearBirthday.setFullYear(thisYearBirthday.getFullYear() + 1);
      }

      return (isAfter(thisYearBirthday, startOfToday) || isEqual(thisYearBirthday, startOfToday)) && isBefore(thisYearBirthday, next30Days);
    })
    .sort((a, b) => {
      const dateA = new Date(a.birthday);
      const dateB = new Date(b.birthday);
      
      const monthA = dateA.getUTCMonth(); // Obtenir le mois (0-11)
      const dayA = dateA.getUTCDate(); // Obtenir le jour (1-31)
      
      const monthB = dateB.getUTCMonth(); // Obtenir le mois (0-11)
      const dayB = dateB.getUTCDate(); // Obtenir le jour (1-31)
      
      // Comparer les mois et ensuite les jours
      if (monthA !== monthB) {
        return monthA - monthB;
      } else {
        return dayA - dayB;
      }
    });

  return coworkersSortByBirthdays;
};

// Thunk asynchrone pour récupérer les coworkers
export const fetchCoworkers = createAsyncThunk(
  'coworker/fetchCoworkers', 
  async (_, { dispatch }) => {
    const coworkers = await showCoworkers();
    const today = new Date().toISOString().split('T')[0];
    const updatedCoworkers = await Promise.all(coworkers.map(async (coworker) => {
      const currentBookingResponse = await dispatch(fetchCurrentBooking({ id: coworker.id, date : today }));
      const currentBooking = currentBookingResponse.payload;
      const {duration_type} = currentBooking
      return { ...coworker, 
            hasCurrentBooking: Object.keys(currentBooking).length !== 0 ,
            isNomade: duration_type?.name === "10 jours"
          };
    }));
    updatedCoworkers.sort((a, b) => a.surname.localeCompare(b.surname));
    return updatedCoworkers;
  }
);

// Thunk asynchrone pour ajouter les infos des coworkers
export const addCoworkers = createAsyncThunk(
  'coworker/add', 
  async ({data}) => {
   
    const response = await addCoworker(data);
    return response;
  }
);

// Thunk asynchrone pour modifier les infos des coworkers
export const updateCoworker = createAsyncThunk(
  'coworker/update', 
  async ({data, id}) => {
    const response = await editCoworker(data, id);
    return response;
  }
);

// Thunk asynchrone pour modifier les infos des coworkers
export const fetchCoworker = createAsyncThunk(
  'coworker/show', 
  async ({id}) => {
    const response = await showCoworker(id);
    return response;
  }
);

//Thunk asynchrone pour le forfait courant d'un coworker
export const fetchCurrentBooking = createAsyncThunk('coworker/booking', async ({ id, date }) => {
  const response = await getCurrentBooking(id, date);
  
  if (response.length === 0){
    return {}
  }

  // Trier la réponse si le forfait courant n'est pas vide.
  response.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
  let lastBooking = response[0];
  const presences = await showAttendancesByBooking(lastBooking.id);

  let joursRestants = null;

  // Calculer le nombre de jours restants en fonction du type de forfait
  if (lastBooking.duration_type.name === "10 jours") {
    joursRestants = lastBooking.duration_type.duration - presences.filter(absence => absence.present).length;
  } else {
    joursRestants = differenceBetweenDates(new Date(lastBooking.end_date), new Date().toISOString())
  }
                
  //Trouver l'espace de travail occupé par chaque forfait au mois
  const assignment = await fetchWorkspaceForBooking(lastBooking.id);

  return { ...lastBooking, remainDays: joursRestants < 0 ? 0 : joursRestants, workspace : assignment[0]?.workspace };
});

//Rendre le coworker actif ou inactif
export const toggleActiveStatus = createAsyncThunk('coworker/toggleStatus', async( {statusCoworker, id}) => {
  const response = await editCoworker({active : statusCoworker}, id)
  return response
})

export const isAlreadyAdded = ({email, coworkers}) => {
  return coworkers.some(co => co.email === email.trim());
};

//Chercher l'espace de travail assigné à un coworker au mois si il y en a un
export const fetchWorkspaceForBooking = async(booking) => {
    const response = await showAssignmentsByBooking(booking);
    return response;
}


const coworkerSlice = createSlice({
  name: 'coworker',
  initialState: {
    coworkers: [],
    allCoworkers: [], // Tous les coworkers non filtrés
    currentBooking : null,
    loadingCoworker : true,
    loadingBooking : true,
    coworkerData : { //Template de données des coworkers
      email: "",
      password: "",
      surname: "",
      job: "",
      file: null,
      contact: "",
      birthday: "",
      notes: "",
      isActive: "true",
      active: true,
      roles: ["ROLE_USER"] // Par défaut, le rôle est utilisateur
    },
    birthdaysNext30Days: [], // Tableau pour les anniversaires des 30 prochains jours
    status: 'idle', // État initial
    error: null, // Gestion des erreurs
  },
  reducers: {
    sortCoworkersByName: (state) => {
      state.coworkers.sort((a, b) => a.surname.localeCompare(b.surname));
    },

    searchCoworkersByName: (state, action) => {
      const searchTerm = action.payload.trim().toLowerCase();
      if (searchTerm === '') {
        state.coworkers = state.allCoworkers;
      } else {
        state.coworkers = state.allCoworkers.filter(coworker =>
          coworker.surname.toLowerCase().includes(searchTerm)
        );
      }
    },

    sortCoworkersByActivity: (state, action) => {
      const isActive = action.payload === 'true';
      if (action.payload === '') {
        state.coworkers = state.allCoworkers;
      } else {
        state.coworkers = state.allCoworkers.filter(coworker => coworker.active === isActive);
      }
    },

    handleChange: (state, action) => {
      const { name, value, fileName, fileData } = action.payload;

      if (fileName) {
         
              state.coworkerData = {
                  ...state.coworkerData,
                  file: fileName,
                  files: fileData
              };
         
        
      } else {
          state.coworkerData = {
              ...state.coworkerData,
              [name]: value
          };
      }
      console.log(state.coworkerData)

   },

   markPresenceForCoworker: (state, action) => {
    const idCoworker = action.payload;
    console.log(idCoworker)
    state.coworkers = state.coworkers.map(coworker=>
      coworker.id === idCoworker ? { ...coworker, isPresent: true } : coworker
    );
  
    state.Coworkers = state.allCoworkers.map(coworker=>
      coworker.id === idCoworker ? { ...coworker, isPresent: true } : coworker
    );
  },
  
    generateRandomPassword : (state, action) => {
    
      const length = action.payload
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
      let password = '';
      for (let i = 0; i < length; ++i) {
          password += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      state.coworkerData = {...state.coworkerData, password : password}
    },
    
    removedCoworkerData : (state) => {
      state.coworkerData = {
        email: "",
        password: "",
        surname: "",
        job: "",
        file: null,
        contact: "",
        birthday: "",
        notes: "",
        arrivedAt : "",
        isActive: "true",
        active: true,
        roles: ["ROLE_USER"]
      }
    },

    addPresenceForCoworkers : (state, action) => {
      const presencesOfToday = action.presences;
      console.log(presencesOfToday)
      const coworkersAlreadyPresent = state.coworkers.map(coworker => {
        const present =  presencesOfToday.some( presence => presence.coworker.id === coworker.id)
        return {
          ...coworker,
          isPresent : present
        }
      })
      console.log(coworkersAlreadyPresent)
      return {
        ...state,
        coworkers : coworkersAlreadyPresent,
        allCoworkers : coworkersAlreadyPresent
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoworkers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCoworkers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const filteredCoworkers = action.payload
        state.coworkers = filteredCoworkers;
        state.allCoworkers = filteredCoworkers; // Stockage de tous les coworkers non filtrés
        
        // Appel de la fonction pour calculer les anniversaires des 30 prochains jours
        state.birthdaysNext30Days = calculateBirthdaysNext30Days(state.coworkers);

      })
      .addCase(addCoworkers.fulfilled, (state, action) => {

        state.coworkers = [...state.coworkers, action.payload]
        state.allCoworkers = [...state.allCoworkers, action.payload]; // Stockage de tous les coworkers non filtrés
        state.status = 'success'
        state.coworkerData = { //Template de données des coworkers
                  email: "",
                  password: "",
                  surname: "",
                  job: "",
                  file: null,
                  contact: "",
                  birthday: "",
                  notes: "",
                  isActive: "true",
                  arrivedAt : "",
                  active: true,
                  roles: ["ROLE_USER"] // Par défaut, le rôle est utilisateur
            }
        // Appel de la fonction pour calculer les anniversaires des 30 prochains jours

      })
      .addCase(fetchCoworkers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCoworker.fulfilled, (state, action) => { 
        state.loadingCoworker = false
        state.coworkerData = {
          email : action.payload.email,
          surname : action.payload.surname,
          job: action.payload.job,
          contact: action.payload.contact,
          file : action.payload.file,
          birthday: action.payload.birthday?.split('T')[0],
          active: action.payload.active,
          arrivedAt: action.payload.arrivedAt?.split('T')[0],
          notes : action.payload.notes,
          roles: ["ROLE_USER"]
        }
      })
      // .addCase(fetchCoworker.pending, (state) => { 
      //     state.loadingCoworker = false
      // })
      .addCase(fetchCurrentBooking.fulfilled, (state, action) => {
          state.loadingBooking = false;
          state.currentBooking = {...action.payload}
          
      })
      .addCase(toggleActiveStatus.fulfilled, (state, action) => {
        state.coworkerData = {...state.coworkerData, active : action.payload.active}
      })
     
  },
});

export const { sortCoworkersByName, 
               searchCoworkersByName, 
               sortCoworkersByActivity, 
               handleChange, 
               generateRandomPassword,
               markPresenceForCoworker,
               addPresenceForCoworkers,
               removedCoworkerData } = coworkerSlice.actions;

export const selectBirthdaysNext30Days = (state) => state.coworker.birthdaysNext30Days;

export default coworkerSlice.reducer;
