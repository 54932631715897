import React from "react";
import { HashRouter, Route, Routes } from 'react-router-dom';
import Layout from "./Layout";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Coworkers from "../pages/Coworkers";
import Reservation from "../pages/Reservation";
import Workspaces from "../pages/Workspaces";
import EditCoworker from "../pages/EditCoworker";
import Bookings from "../pages/Bookings";
import ShowCoworker from "../pages/ShowCoworker";
import CalendarComponent from "./CalendarComponent";
import Booking from "../pages/Booking";
import TestComponent from "./TestComponent";
import Historitical from "../pages/Historitical";




const App = () => {

    return (

            <HashRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route element={<Layout />}>
                        <Route path="/dashboard" element={ <Dashboard />} />
                        <Route path="/tests" element={ <TestComponent />} />
                        <Route path="/booking" element= { <Booking />}/>
                        <Route path="/coworkers" element={<Coworkers />} />
                        <Route path="/coworkers/add" element={<EditCoworker />} />
                        <Route path="/coworkers/edit/:idCoworker" element={<EditCoworker />} />
                        <Route path="/coworkers/:idCoworker" element={<ShowCoworker />} />
                        <Route path="/reservations" element={<Reservation />} />
                        <Route path="/bookings" element={<Bookings />} />
                        <Route path="/workspaces" element={<Workspaces />} />
                        <Route path="/calendar" element={<CalendarComponent />} />
                        <Route path="/attendances" element={<Historitical />} />
                    </Route>
                </Routes>
            </HashRouter>
     
    );
}

export default App;
