const urlCoworkers = `${process.env.REACT_APP_API_URL}coworkers`; 
const urlBookings = `${process.env.REACT_APP_API_URL}bookings`;
const urlAssignments = `${process.env.REACT_APP_API_URL}assignments`; 
const token = localStorage.getItem('token');

export const fetchUser = async (userId) => {

    try{
        const response = await fetch(`${urlCoworkers}/${userId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
        });
        if (!response.ok) {
            throw new Error('Erreur lors de la récupération de l\'utilisateur');
        }
        return response.json();

    } catch (error){
        console.error("Erreur lors de la récupération de  l'utilisateur", error);
        throw error; 
    }
}

export const addCoworker = async (coworkerData) => {

     // Récupérer le token JWT depuis le stockage local
    let response = null;
    try{
          response = await fetch(`${urlCoworkers}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
            body: JSON.stringify(coworkerData), // Les données du coworker à ajouter
         });

         if (!response.ok) {
            throw new Error('Erreur lors de la récupération de l\'utilisateur');
        }

    }catch (error){
        console.error("Erreur lors de l'ajout du coworker'", error);
        throw error; 
    }
   

    return await response.json();
}


export const showCoworkers = async () => {
   
    let allMembers = [];
    const baseUrl = 'https://admin.9coworking.fr';

    try {
        let nextPageUrl = urlCoworkers;

        while (nextPageUrl) {
            const response = await fetch(nextPageUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/ld+json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des coworkers');
            }   

            const data = await response.json();

            // Ajouter les membres de la page actuelle à l'ensemble des membres
            allMembers = allMembers.concat(data['hydra:member']);

            // Vérifier s'il y a une page suivante dans hydra:view
            const view = data['hydra:view'];
        
            if (view && view['hydra:next']) {
                nextPageUrl = `${baseUrl}${view['hydra:next']}`;
            } else {
                nextPageUrl = null;
            }
        }

        return allMembers;
    } catch (error) {
        console.error("Erreur lors de la récupération des coworkers'", error);
        throw error;
    }
};




export const showCoworker = async (id) => {
   
  
    try{
          const response = await fetch(`${urlCoworkers}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
         });

         if (!response.ok) {
            throw new Error('Erreur lors de la récupération des coworkers');
        }

        const data = await response.json();
        return data

    }catch (error){
        console.error("Erreur lors de la récupération des coworkers'", error);
        throw error; 
    }
   

}


export const deleteCoworker = async (coworkerId) => {
    try {
  
        const response = await fetch(`${urlCoworkers}/${coworkerId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                // Vous pouvez également inclure des headers supplémentaires si nécessaire, comme le jeton d'authentification
            },
        });
        
        if (!response.ok) {
            throw new Error('La suppression du coworker a échoué');
        }

        return response.json();
    } catch (error) {
        throw new Error('Erreur lors de la suppression du coworker : ' + error.message);
    }
};


export const showCoworkerByName = async (coworkerName) => {
    try {
    
        const response = await fetch(`${urlCoworkers}?surname=${coworkerName}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                // Vous pouvez également inclure des headers supplémentaires si nécessaire, comme le jeton d'authentification
            },
        });
        
        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des coworkers');
        }

        const data = await response.json();
    
        return data['hydra:member']


    } catch (error) {
        throw new Error('Erreur lors de la recherche du coworker : ' + error.message);
    }
};



export const getProfilUrl = (fileName) => {
    return process.env.REACT_APP_BASE_URL + 'uploads/' + fileName
}


export const editCoworker = async (coworkerData, idCoworker) => {
   
  
    let response = null;
    try{
          response = await fetch(`${urlCoworkers}/${idCoworker}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/merge-patch+json',
            },
            body: JSON.stringify(coworkerData), // Les données du coworker à ajouter
         });

         if (!response.ok) {
            throw new Error('Erreur lors de la modification de l\'utilisateur');
        }

    }catch (error){
        console.error("Erreur lors de la modification du coworker'", error);
        throw error; 
    }
   

    return await response.json();
}



export const getCurrentBooking = async(idCoworker, date) => {
   

    try{
        
        const response = await fetch(`${urlBookings}?coworker=${idCoworker}&start_date[before]=${date}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }

        })

        if (!response.ok){
            throw new Error('Erreur lors de la récupération du forfait courant');
        }

        const data = await response.json();

        return data['hydra:member']
    }catch(error){
        console.error('Erreur lors de la récupération du forfait courant');
        throw error;
    }
}

export const showAssignments = async () => {
   
    let allAssignments = [];
    const baseUrl = 'https://admin.9coworking.fr';

    try {
        let nextPageUrl = urlAssignments;

        while (nextPageUrl) {
            const response = await fetch(nextPageUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/ld+json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des assignations');
            }   

            const data = await response.json();

            // Ajouter les membres de la page actuelle à l'ensemble des membres
            allAssignments = allAssignments.concat(data['hydra:member']);

            // Vérifier s'il y a une page suivante dans hydra:view
            const view = data['hydra:view'];
        
            if (view && view['hydra:next']) {
                nextPageUrl = `${baseUrl}${view['hydra:next']}`;
            } else {
                nextPageUrl = null;
            }
        }

        return allAssignments;
    } catch (error) {
        console.error("Erreur lors de la récupération des coworkers'", error);
        throw error;
    }
};

export const showAssignmentsByBooking = async ( idBooking) => {

    let allAssignments = [];
    const baseUrl = 'https://admin.9coworking.fr';
    const urlAssignments = `${baseUrl}/api/assignments`; // Assurez-vous que cette URL est correcte

    try {
        let nextPageUrl = `${urlAssignments}?booking=${idBooking}`;

        while (nextPageUrl) {
            const response = await fetch(nextPageUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/ld+json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des assignations');
            }

            const data = await response.json();

            // Ajouter les membres de la page actuelle à l'ensemble des membres
            allAssignments = allAssignments.concat(data['hydra:member']);

            // Vérifier s'il y a une page suivante dans hydra:view
            const view = data['hydra:view'];
            if (view && view['hydra:next']) {
                nextPageUrl = `${baseUrl}${view['hydra:next']}`;
            } else {
                nextPageUrl = null;
            }
        }

        return allAssignments;
    } catch (error) {
        console.error("Erreur lors de la récupération des coworkers", error);
        throw error;
    }
};

export const editAssignment = async(assignmentData,idAssignment) => {
   
    let response = null;

    try{
        response = await fetch(`${urlAssignments}/${idAssignment}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/merge-patch+json',
            },
            body: JSON.stringify(assignmentData)
        });
        if (!response.ok) {
            throw new Error('Erreur lors de la modification de l\'assignation');
        }
    }catch(error){
        console.error("Erreur lors de la modification de l'assignation'", error);
        throw error;
    }
    return await response.json();
}
export const addAssignment = async(assignmentData) => {

    let response = null;

    try{
        response = await fetch(`${urlAssignments}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
            body: JSON.stringify(assignmentData)
        });
        if (!response.ok) {
            throw new Error('Erreur lors de la modification de l\'assignation');
        }
    }catch(error){
        console.error("Erreur lors de la modification de l'assignation'", error);
        throw error;
    }
    return await response.json();
}
