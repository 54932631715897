import React, { useState, useEffect } from 'react';
import ical from 'ical.js';

const CalendarComponent = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Télécharger le fichier .ics à partir du lien
        const response = await fetch('https://calendar.google.com/calendar/ical/hello%409coworking.fr/public/basic.ics');
        const data = await response.text(); // Obtenir le texte de la réponse
        
        // Analyser le fichier .ics
        const jcalData = ical.parseICS(data);
        const comp = new ical.Component(jcalData);
        const vevents = comp.getAllProperties('vevent');
        
        // Convertir les événements a format JSON
        const parsedEvents = vevents.map(vevent => {
          return {
            summary: vevent.getFirstPropertyValue('summary'),
            start: new Date(vevent.getFirstPropertyValue('dtstart')),
            end: new Date(vevent.getFirstPropertyValue('dtend')),
          };
        });

        // Mettre à jour l'état avec les événements récupérés
        setEvents(parsedEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, []);

  return (
    <div>
      <h2>Upcoming Events:</h2>
      <ul>
        {events.map((event, index) => (
          <li key={index}>
            {event.summary} - {event.start.toLocaleString()} to {event.end.toLocaleString()}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CalendarComponent;
