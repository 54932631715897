// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/userSlice';
import bookingReducer from './features/bookingSlice';
import coworkerReducer from './features/coworkerSlice';
import workspaceReducer from './features/workspaceSlice';
import reservationReducer from './features/reservationSlice'
import attendanceReducer from './features/attendanceSlice';
import assignmentReducer from './features/assignmentSlice';

const actionSanitizer = (action) => (
  action.type === 'FILE_DOWNLOAD_SUCCESS' && action.data ?
  { ...action, data: '<<LONG_BLOB>>' } : action
);

const store = configureStore({
  reducer: {
    user: userReducer,
    booking: bookingReducer,
    coworker: coworkerReducer,
    workspace : workspaceReducer,
    reservation : reservationReducer,
    attendance : attendanceReducer,
    assignment : assignmentReducer
  },
  devTools: {
    maxAge: 70
  }
});

console.log(store.getState)

export default store;
