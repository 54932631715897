import { useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const useTokenExpirationChecker = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const interval = setInterval(() => {
        if (isTokenExpired(token)) {
          localStorage.removeItem('token');
          navigate('/'); // Redirigez vers la racine du site
        }
      }, 1000); // Vérifiez chaque seconde
      return () => clearInterval(interval);
    }
  }, [navigate]);

  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
 
      const currentTime = Date.now() / 1000;
      return decoded.exp < currentTime;
    } catch (e) {
      return true;
    }
  };
};

export default useTokenExpirationChecker;
