import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addReservations, showReservations } from "../hooks/useDbReservation";
import { initGoogleClient, fetchGoogleEvents, getEvents, gapiLoaded, listUpcomingEvents } from '../api/googleReservationData';

export const fetchReservations = createAsyncThunk('reservation/fetchReservation', async () => {
    await gapiLoaded(); // Assurez-vous que GAPI est initialisé avant de continuer

    const [googleEvents, localReservations] = await Promise.all([
        listUpcomingEvents(), // Récupérer les événements Google
        showReservations(),  // Récupérer les réservations locales
    ]);

    const mergedReservations = [...localReservations, ...googleEvents];
    return mergedReservations;
});
export const addReservation = createAsyncThunk('reservation/addReservation', async({data}) => {
    const response = await addReservations(data);
    return response;
});

const reservationSlice = createSlice({
    name: 'reservation',
    initialState: {
        reservations: [],
        reservationsOfToday: [],
        reservationData: { workspace: "", coworker: "", startDate: "", startTime: "", endTime: "" },
        status: 'idle',
        isModalOpen: false,
        alert: { show: false, severity: "", message: "" }
    },
    reducers: {
        handleChange: (state, action) => {
            const { name, value } = action.payload.target;
            state.reservationData = { ...state.reservationData, [name]: value };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReservations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchReservations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.reservations = action.payload;
                state.reservationsOfToday = action.payload.filter(r => 
                    (r.start_date ? r.start_date.split('T')[0] : r.start.dateTime.split('T')[0]) === new Date().toISOString().split('T')[0]
                );
            })
            .addCase(fetchReservations.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(addReservation.fulfilled, (state, action) => {
                state.reservations = [...state.reservations, action.payload];
                state.reservationsOfToday = state.reservations.filter(r => 
                    (r.start_date ? r.start_date.split('T')[0] : r.start.dateTime.split('T')[0]) === new Date().toISOString().split('T')[0]
                );
                const msg = { show: true, message: "Réservation Ajoutée avec succès", severity: "success" };
                state.alert = msg;
            })
            .addCase(addReservation.rejected, (state) => {
                const msg = { show: true, message: "Erreur lors de l'ajout de la réservation", severity: "error" };
                state.alert = msg;
            });
    }
});

export const { handleChange } = reservationSlice.actions;

export default reservationSlice.reducer;
