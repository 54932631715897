import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addAttendance, showAttendances, updateAttendance } from "../hooks/useDbPresence";
const today = new Date().toISOString().split('T')[0];


export const fetchAttendances = createAsyncThunk('attendances/fetchAttendances' , async() => {
    const attendances = await showAttendances();
    return attendances; 
})



export const addAttendances = createAsyncThunk('attendances/add', async({data}) => {
    const response = await addAttendance(data);
    return response;
})

export const updateAttendances= createAsyncThunk('attendances/update', async({id, data}) => {
    const response = await updateAttendance(id,data);
    return response;
})

const getPresencesOfToday = ({ bookings, attendances }) => {
    const today = new Date().toISOString().split('T')[0];

    // Filtrage des présences pour aujourd'hui
    const presencesOfToday = attendances.filter(attendance => attendance.present && attendance.date.split('T')[0] === today);

    // Ajout du nombre de jours restants pour chaque présence
    const presencesWithRemainDays = presencesOfToday.map(p => {
        const booking = bookings.find(booking => booking.id === p.booking.id);
        if (booking) {
            const remainDays = booking.duration_type.duration - attendances.filter(a => a.booking.id === booking.id && a.present).length; // Calcul des jours restants
            return { ...p, remainDays };
        }
        return p;
    });

    return presencesWithRemainDays;
};

export const fetchPresencesOfToday = createAsyncThunk(
    'attendance/fetchPresencesOfToday',
    async ({ bookings, attendances, today }, { rejectWithValue }) => {
        try {
            const presencesWithRemainDays = getPresencesOfToday({ bookings, attendances });
            return presencesWithRemainDays;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const attendanceSlice = createSlice({
    name : "attendance", 
    initialState : {
        attendances : [],
        presencesOfToday : [],
        attendanceData : {
            date: today,
            coworker: "",
            workspace:"",
            booking: null,
            present: true
        },
        status : 'idle',
        error: null
    }, 
    reducers: {
        updateAttendanceData: (state, action) => {
          
            const { name, value } = action.payload;
            state.attendanceData[name] = value;
            
        },
        replaceData : (state, action) => {
            state.attendanceData = action.payload
        }
    },
    extraReducers: (builder) => {
        builder 
            .addCase(fetchAttendances.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(fetchAttendances.fulfilled, (state, action) => { 
                state.attendances = action.payload
                state.status = 'succeded'
            })
            .addCase(addAttendances.fulfilled, (state, action) => { 
                state.attendances = [...state.attendances, action.payload]

            })
            .addCase(updateAttendances.fulfilled, (state, action) => { 
       
                const index = state.attendances.findIndex(attendance => attendance.id === action.payload.id);
                if (index !== -1) {
                    state.attendances[index] = action.payload;
                }

            })
            .addCase(fetchPresencesOfToday.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.presencesOfToday = action.payload;
            })
    }
})

export default attendanceSlice.reducer
export const {updateAttendanceData, replaceData} = attendanceSlice.actions
export const valueOfattendances = state => state.attendance.attenadances