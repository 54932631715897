const Auth = {
  login: async (username, password) => {
    try {
    
      const response = await fetch(process.env.REACT_APP_API_URL + 'login_check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      if (!response.ok) {
        throw new Error('Erreur de connexion');
      }

      const data = await response.json();
      localStorage.setItem('token', data.token);
      
      
      if (data) {
        const idResponse = await fetch(process.env.REACT_APP_API_URL + `coworkers?email=${username}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${data.token}`,
            'Content-Type': 'application/json',
          },
        });

        if (idResponse.ok) {
          const userData = await idResponse.json();
          localStorage.setItem('user_id', userData['hydra:member'][0].id);
          return userData['hydra:member'][0];
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'authentification : ", error);
    }
  },

  isAuthenticated: () => {
    return !!localStorage.getItem('user_id');
  },

  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
  }
};

export default Auth;
