import { gapi } from 'gapi-script';


const API_KEY = 'AIzaSyANik5y8AuGHtxhRHE679-RmT7pUGeJH94'; // Remplacez par votre clé API réelle
const CALENDAR_ID = 'reunion@9coworking.fr'; // Remplacez par l'ID de votre calendrier
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];

// Fonction pour charger la bibliothèque GAPI et initialiser le client
export function gapiLoaded() {
    return new Promise((resolve, reject) => {
        gapi.load('client', () => {
            initializeGapiClient().then(resolve).catch(reject);
        });
    });
}

// Fonction pour initialiser le client GAPI
async function initializeGapiClient() {
    try {
        await gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: DISCOVERY_DOCS,
        });
        console.log("GAPI client initialized");
    } catch (error) {
        console.error("Error initializing GAPI client", error);
        throw error; // Rejeter la promesse en cas d'erreur
    }
}

// Fonction pour lister les événements à venir
export async function listUpcomingEvents() {
    let response;
    try {
        const request = {
            'calendarId': CALENDAR_ID,
            'timeMin': '2024-07-21T00:00:00Z',
            'showDeleted': false,
            'singleEvents': true,
            'maxResults': 80,
            'orderBy': 'startTime',
        };
   
        response = await gapi.client.calendar.events.list(request);
    
    } catch (err) {
        console.error('Bah erreur hein' + err.message);
        throw err; // Rejeter la promesse en cas d'erreur
    }
  
    const events = response.result.items;
    console.log(events);  console.log(events);
    return events;
}