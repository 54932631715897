import React, { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';

const API_KEY = 'AIzaSyANik5y8AuGHtxhRHE679-RmT7pUGeJH94';
const CALENDAR_ID = 'reunion@9coworking.fr'; // Replace with your public calendar ID
const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];

const TestComponent = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const initializeGapiClient = async () => {
      await gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: DISCOVERY_DOCS,
      });

      listUpcomingEvents();
    };

    const listUpcomingEvents = async () => {
      try {
        const response = await gapi.client.calendar.events.list({
          'calendarId': CALENDAR_ID,
          'timeMin': (new Date()).toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 10,
          'orderBy': 'startTime',
        });

        const events = response.result.items;
        if (events.length) {
          setEvents(events);
        } else {
          setEvents([]);
        }
      } catch (err) {
        console.error('Error fetching events', err);
      }
    };

    gapi.load('client', initializeGapiClient);
  }, []);

  return (
    <div>
      <h1>Google Calendar Events</h1>
      <pre id="content" style={{ whiteSpace: 'pre-wrap' }}>
        {events.length !== 0 ? (
          events.map(event => (
            <div key={event.id}>
              {event.summary} ({event.start.dateTime || event.start.date})
            </div>
          ))
        ) : (
          <div>No events found.</div>
        )}
      </pre>
    </div>
  );
};

export default TestComponent;
