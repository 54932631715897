import React, {useState } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/fr';

import addIcon from "../assets/images/addIcon.svg"

import Modal from "../components/Modal";
import { PuffLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { addAttendances } from "../features/attendanceSlice";
import { fetchReservations } from "../features/reservationSlice";



moment.locale('fr');
const localizer = momentLocalizer(moment);



const Reservation = () => {

    const dispatch = useDispatch();

    const {coworkers} = useSelector(state => state.coworker);
    const {reservations} = useSelector(state => state.reservation);
    const {workspaces} = useSelector(state => state.workspace);

    const [loading, setLoading] = useState(false);
    const [isReservationModalOpen, setReservationModalOpen] = useState(false);

    const [reservationData, setReservationData] = useState({
        workspace: "", // ID de l'espace de travail sélectionné
        coworker: "", // ID du coworker sélectionné
        startDate: "", // Date de début de la réservation
        startTime: "", // Heure de début de la réservation
        endTime: "" // Heure de fin de la réservation
    });
    


    const handleChangeReservation = (e) => {
        const { name, value } = e.target;
        setReservationData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };



    const openReservationModal = () => {
        setReservationModalOpen(true);
    };

    const closeReservationModal = () => {
        setReservationModalOpen(false);
    };


    const handleAddReservation = async (e) => {
        e.preventDefault();
        try {

            const formattedDataReservation = {
                coworker : reservationData.coworker,
                workspace : reservationData.workspace,
                start_date : reservationData.startDate + 'T' + reservationData.startTime,
                end_date : reservationData.startDate + 'T' + reservationData.endTime
            }
            dispatch(addAttendances(formattedDataReservation))
            closeReservationModal();
         
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'ajout de la réservation :", error);
        }
    }



    const eventStyleGetter = (event) => {

        let backgroundColor = '#3788d8'; // Default background color
        if (event.location?.toLowerCase() === 'salle de reunion 1') {
          backgroundColor = '#BF2626';
        } else if (event.location?.toLowerCase() === 'salle de reunion 2') {
          backgroundColor = '#44A677';
        }
        
        return {
          style: {
            backgroundColor,
            color: 'white',
            borderRadius: '0px',
            opacity: 0.8,
            display: 'block',
          },
        };
      };


    return <React.Fragment >
        <main className="main">
            <div className="main__actions">
                <p className="main__add" onClick={openReservationModal}>
                    <img src={addIcon} alt="Ajouter une réservation" />
                </p>
            </div>

            <h1 className="main__heading">
                Réservations de la salle réunion du 9 coworking
            </h1>

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '80vh' }}>
                    <PuffLoader color="#BF2626" size={140} loading />
                </div>
            ) : 
        
            <div>
                <Calendar
                    localizer={localizer}
                    events={reservations?.map(reservation => ({
                    start: new Date(reservation.start_date || reservation.start.dateTime), 
                    end: new Date(reservation.end_date || reservation.end.dateTime), 
                    key : reservation.id,
                    title: reservation.coworker?.surname || "Réservé par 9coworking",
                    location : reservation.location
                    }))}
                    startAccessor="start"
                    endAccessor="end"
                    eventPropGetter={eventStyleGetter}
                    style={{ height: 500 ,fontFamily: "Montserrat" }}
                />
            </div>
  
        }
        </main>


        <Modal isOpen={isReservationModalOpen} onClose={closeReservationModal}>
                   
                    <form action="" className="form" onSubmit={handleAddReservation}>
                        <h2 className="form__title">Ajouter une réservation</h2>
                            <select name="workspace" id="" className="form__input" value={reservationData.workspace} onChange={handleChangeReservation}>
                                <option value="" >Salle de réunion</option>
                                {
                                    workspaces?.filter(workspace => workspace.type.name === 'Salle de réunion').map((workspace) => {
                                        return <option value={`/api/workspaces/${workspace.id}`} key={workspace.id}>{workspace.name}</option>
                                    })
                                }

                            </select>

                            <select name="coworker" id="" className="form__input" value={reservationData.coworker} onChange={handleChangeReservation}>
                                <option value="" >Coworker</option>
                                {
                                    coworkers?.map((coworker) => {
                                        return <option value={`/api/coworkers/${coworker.id}`} key={coworker.id}>{coworker.surname}</option>
                                    })
                                }
                            </select>

                            <input type="date" name="startDate" className="form__input" placeholder="choisir une date" onChange={handleChangeReservation}/>

                            <div className="form__time">
                                <div>
                                    <label htmlFor="">Heure de début</label><br></br>
                                    <input aria-label="Time" name="startTime" type="time" className="form__input" onChange={handleChangeReservation}/>
                                </div>

                                <div>
                                    <label htmlFor="">Heure de Fin</label><br></br>
                                    <input aria-label="Time" type="time" name="endTime" className="form__input" onChange={handleChangeReservation}/>
                                </div>
                            </div>
                         
                          

                            <button type="submit" className="form__submit">
                                Valider
                            </button>
                    </form>
        </Modal>
    </React.Fragment>
}

export default Reservation;