import React, { useEffect, useState } from "react";
import Auth from "../hooks/useAuth";
import {  useNavigate } from "react-router";
import { Eye, EyeOff, Send, TriangleAlert } from "lucide-react";

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [formErrors, setFormErrors] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

     useEffect(() => {
         localStorage.clear();
     }, [])

    const handleSubmit = async(evt) =>{
        evt.preventDefault();
    
        try{
   
            const response = await Auth.login(email, password);

            if (response){

                // setUser({ id: response.id, name: response.user_name });
                localStorage.setItem('user_id', response.id);
                setTimeout(()=>{
                    response.roles.includes("ROLE_ADMIN") ? navigate('/dashboard') : navigate('/booking')
                    
                }, 1000)
                
            }else{
                setFormErrors(true)
            }
           

        }catch(error){
           console.error(error);
            
        }
       
    }

    return <section className="login">
        <div className="login__main">
           

             <h1 className="login__subtitle">Connexion</h1>
             <h2 className="login__title">Bonjour ! Bienvenue dans le panel d'administration du <span>9coworking</span></h2>
             {formErrors && 
             <div className="login__error">
                <TriangleAlert />
                <p>Email ou mot de passe incorrect. Veuillez réessayer !</p>
             </div>
             }
             
            <form action="" onSubmit={(evt)=>handleSubmit(evt)} className="form">
                <div className="form__group">
                   <label htmlFor="email" className="form__label">Email</label>
                    <input 
                        id="email"
                        type="email" 
                        placeholder="Entrez votre Email" 
                        className="login__input" 
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    /> 
                </div>
                
                <div className="form__group">
                    <label htmlFor="pwd" className="form__label">Mot de passe</label>
                    <div className="form__group--password">
                        <input 
                        
                        id="pwd"
                        type={showPassword ? "text" : "password"} 
                        placeholder="Entrez votre mot de passe" 
                        className="login__input" 
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        />

                        {!showPassword ? <Eye className="form__eyeIcon" onClick={() => setShowPassword(!showPassword)}/> 
                                      : <EyeOff className="form__eyeIcon" onClick={() => setShowPassword(!showPassword)}/>}
                    </div>
                </div>
                 

                 <button type="submit" className="login__submit">
                    Se connecter
                    <Send size={25} />
                 </button>
            </form>
             
        </div>
      
 
    </section>
}

export default Login;