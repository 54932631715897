const urlAttendances = `${process.env.REACT_APP_API_URL}attendances`; 

export const showAttendances = async() => {
    const token = localStorage.getItem('token');
    let allAttendances = [];
    const baseUrl = 'https://admin.9coworking.fr';

    try {
        let nextPageUrl = urlAttendances;

        while (nextPageUrl) {
            const response = await fetch(nextPageUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/ld+json',
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des présences');
            }

            const data = await response.json();
            allAttendances = allAttendances.concat(data['hydra:member']);

            const view = data['hydra:view'];
            nextPageUrl = view && view['hydra:next'] ? `${baseUrl}${view['hydra:next']}` : null;
        }

        return allAttendances;
    } catch (error) {
        console.error("Erreur lors de la récupération des présences", error);
        throw error;
    }
};



export const showAttendancesById = async(idCoworker) => {

    const token = localStorage.getItem('token');
    try{

        const response = await fetch(`${urlAttendances}?coworker=${idCoworker}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
        })

        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des présences');
         }
         const data = await response.json();
     
        return data['hydra:member'];
    }catch(error){
        console.error(error)
    }

}

export const showAttendancesByBooking = async(idBooking) => {

    const token = localStorage.getItem('token');
    try{

        const response = await fetch(`${urlAttendances}?booking=${idBooking}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
        })

        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des présences');
         }
         const data = await response.json();
     
        return data['hydra:member'];
    }catch(error){
        console.error(error)
    }

}


export const addAttendance = async(attendanceData) => {
    const token = localStorage.getItem('token');
    try{

        const response = await fetch(`${urlAttendances}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
            body: JSON.stringify(attendanceData)
        })

        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des présences');
         }
         const data = await response.json();
     
        return data;
    }catch(error){
        console.error(error)
    }
}

export const updateAttendance = async( id , attendanceData) => {
    const token = localStorage.getItem('token');
    try{

        const response = await fetch(`${urlAttendances}/${id}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/merge-patch+json',
            },
            body: JSON.stringify(attendanceData)
        })

        if (!response.ok) {
            throw new Error('Erreur lors de la modification des présences');
         }
         const data = await response.json();
     
        return data;
    }catch(error){
        console.error(error)
    }
}









