import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router";
import { getProfilUrl} from "../hooks/useDbCoworker";
import yesIcon from '../assets/images/yesIcon.svg'
import noIcon from '../assets/images/noIcon.svg'
import { Link } from "react-router-dom";
import { ScaleLoader } from "react-spinners";

import {addDays, addMonths, endOfMonth, format, startOfMonth } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { addAttendance, showAttendancesByBooking, updateAttendance } from "../hooks/useDbPresence";
import Modal from "../components/Modal";
import Alert from "../components/Alert";
import { Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoworker, fetchCurrentBooking, toggleActiveStatus } from "../features/coworkerSlice";
import MyImage from "../components/MyImage";
import { addBooking } from "../features/bookingSlice";
import { ChevronDown, ChevronLeft, CirclePower, Minus, Pencil } from "lucide-react";
import { addAssignments, updateAssignment } from "../features/assignmentSlice";


import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { getFreeWorkspaces } from "../features/workspaceSlice";


const ShowCoworker = () => {

    const dispatch = useDispatch();
    const params = useParams();

    const {attendances} = useSelector(state => state.attendance);
    const {currentBooking, coworkerData} = useSelector(state => state.coworker);
    const {durationTypes, bookingTypes, bookings} = useSelector(state => state.booking);
    const {workspaces, freeWorkspaces} = useSelector(state => state.workspace);
    const {assignments} =  useSelector(state => state.assignment);
   
 

    const idCoworker = params?.idCoworker;
    const navigate = useNavigate();
   
    const [days, setDays] = useState([]);
    const [isForfaitModalOpen, setForfaitModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isworkspaceModalOpen, setIsworkspaceModalOpen] = useState(false);
    const [alert, setAlert] = useState({ show: false, severity: "", message: "" });
    const [loading, setLoading] = useState(false)

    const [attendance, setAttendance] = useState({
        date: null,
        coworker: `api/coworkers/${idCoworker}`,
        booking: null,
        workspace : null,
        present: true
    });
 

    const [bookingData, setBookingData] = useState({
        name: "",
        coworker: `/api/coworkers/${idCoworker}`, // ID du coworker sélectionné
        type: "",
        duration_type: "",
        start_date: "", // Date de début de la réservation
        end_date: "" // Heure de fin de la réservation
    });


    const showAlert = useCallback((severity, message) => {
        setAlert({ show: true, severity, message });
     
    }, []);

    // Date d'aujourd'hui
    const today = new Date().toISOString().split('T')[0];

    // Récupérer les présences ou les absences d'un coworker pour le jour courant
    const showAbsences = useCallback(async () => {
        if (currentBooking) {
            const allAbsences = await showAttendancesByBooking(currentBooking?.id);
            
            const filteredAbsences = allAbsences.filter(absence => currentBooking?.duration_type?.name === 'Mois' ? !absence.present : absence.present);
            const sortedAbsences = filteredAbsences.sort((a, b) => new Date(a.date) - new Date(b.date));
            setDays(sortedAbsences);
        } else {
            setDays([]);
        }
    },[currentBooking]);

    //Récupérer les données du coworker et du forfait courant
    const getAllData = useCallback(async () => {
        dispatch(fetchCoworker({ id: idCoworker }));
        dispatch(fetchCurrentBooking({ id: idCoworker, date: today , attendances : attendances}));

        if (currentBooking?.duration_type?.name === 'Mois'){
            const currentAttendanceData = {...attendance, workspace : `/api/workspaces/${currentBooking?.workspace?.id}`}
            setAttendance(currentAttendanceData )
        }
    }, [idCoworker, currentBooking?.duration_type?.name]);

    useEffect(() => {
        getAllData();
       
        const timer = setTimeout(() => {
            setLoading(true)
        }, 2000);
        setLoading(false)
        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
   
    }, [getAllData]);

    useEffect(() => {
        if (currentBooking){
            showAbsences(); 
        }
      
    }, [currentBooking, showAbsences]);
    

    //Initialiser le state attendance en y ajoutant l'IRI de l'id ?
    useEffect(() => {
        if (currentBooking) {
            setAttendance(prevAttendance => ({
                ...prevAttendance,
                booking: `api/bookings/${currentBooking.id}`
            }));
        }
      
    }, [currentBooking]);

    
    const handleChangeBooking = (e) => {
        const { name, value } = e.target;
       
        setBookingData(prevState => ({
            ...prevState,
            [name]: value
        }));

    };

    //Gestion des actions faites sur les présences/absences des coworkers
    const handleValidation = async(e) => {

        e.preventDefault();
        setIsModalOpen(false)
       
        try {
            const existingAttendances = await showAttendancesByBooking(currentBooking.id);
            const existingAttendance = existingAttendances.find(a => 
                {
                    return a.date.split('T')[0] === attendance.date.split('T')[0]
                }
            );
    
            if (existingAttendance) {
                console.log('salut1')
                if (existingAttendance.present === attendance.present) {
                     showAlert("warning", "L'attendance pour cette date existe déjà avec le même état.");
                     
                } else {
               
                    await updateAttendance(existingAttendance.id, { present: attendance.present });
                    showAlert("success", "L'attendance a été mise à jour.");
                }
            } else {
                
                await addAttendance(attendance);
                showAlert("success", "L'attendance a été ajoutée.");
            }
    
            await showAbsences(currentBooking); // Mettre à jour la liste des absences après ajout ou mise à jour
        } catch (error) {
            console.error("Erreur lors de la validation de l'attendance :", error);
            showAlert("error", "Erreur lors de la validation de l'attendance.");
        }
        setTimeout(() => {
            setAlert({...alert, show :  false})
          }, "2000");
        dispatch(fetchCurrentBooking({ id: idCoworker, date: today , attendances : attendances}))
        
    };
    
    //Ajouter un forfait pour le coworker courant
    const handleAddBookings = async (e) => {
        e.preventDefault();
        try {
            // Set loading state to true
            setLoading(false);
            
            // Dispatch the action to add booking
            dispatch(addBooking({bookingData : bookingData}));
            
            // Fetch the current booking data again
            dispatch(fetchCurrentBooking({ id: idCoworker, date: today, attendances: attendances }));
            
            // Close the forfait modal
            setForfaitModalOpen(false);
    
            // Use a timer to simulate a loading state for 2 seconds
            setTimeout(() => {
                setLoading(true);
            }, 3000);
            dispatch(fetchCurrentBooking({ id: idCoworker, date: today, attendances: attendances }));
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'ajout du forfait :", error);
            // Ensure loading state is turned off if there's an error
            setLoading(true);
        }
    };
    
    // const getTomorrowDate = () => {
    //     return addDays(new Date(), 0);
    //   };
      
      // Fonction pour obtenir la date dans trois mois à partir de demain
      const getDateInThreeMonths = (date) => {
        return addMonths(date, 3);
      };

      //Fonction de renouvellement de forfait
      const handleCreateNewBooking = () => {
        const booking10Days = currentBooking.duration_type.name === '10 jours'
        let newBooking = {
            ...currentBooking, 
            coworker : currentBooking.coworker["@id"],
            duration_type : currentBooking.duration_type["@id"],
            type : currentBooking.type["@id"],
            start_date : (booking10Days ? new Date() : addDays(startOfMonth(new Date()), 1)).toISOString().split('T')[0]
        }
    
        newBooking = {...newBooking,  
                        end_date : (booking10Days ? getDateInThreeMonths(newBooking.start_date).toISOString().split('T')[0]
                                                                                 : endOfMonth(new Date(), 1).toISOString().split('T')[0])
                    }
    
        delete newBooking.remainDays;
        delete newBooking.id;
        delete newBooking["@id"];
        delete newBooking["@type"];
                    
        console.log(new Date());
        console.log(startOfMonth(new Date()).toISOString())

        
        dispatch(addBooking({bookingData: newBooking}));
        
        
        const timer = setTimeout(() => {
            setLoading(true);
        }, 2000);
        setLoading(false);
        dispatch(fetchCurrentBooking({id: idCoworker, date: today, attendances : attendances}));
        return () => clearTimeout(timer);
    }
    

    const handleClickMinus = (d) => {
        setAttendance({...attendance, date : d, present: !attendance.present})
        setIsModalOpen(true)
    }

    //Gérer l'activité d'un coworker
    const handleClick = () => {
        dispatch(toggleActiveStatus({ statusCoworker: !coworkerData.active, id: idCoworker }));
      };

    //Attribution d'un espace de travail à un coworker
    const handleAddAssignment = async(e) => {
        e.preventDefault();

        const assignmentData = {
            workspace : e.target.workspace.value,
            booking : `/api/bookings/${currentBooking.id}`
        }
        console.log(assignmentData);
        setIsworkspaceModalOpen(false);
        if (!currentBooking.workspace){
            dispatch(addAssignments({data : assignmentData}))
        }else {
            dispatch(updateAssignment({data : assignmentData}))
        }
        dispatch(fetchCurrentBooking({id: idCoworker, date: today, attendances : attendances}))
        const timer = setTimeout(() => {
            setLoading(true)
        }, 2000);
        setLoading(false)
        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
        
    }

    const getWorkspaceWithCoworkers = useCallback(() => {

        dispatch(getFreeWorkspaces({assignments : assignments, workspaces : workspaces, attendances : attendances}))


    }, [attendances, workspaces, today, assignments]);

    useEffect(() => {
        getWorkspaceWithCoworkers()
    }, [getWorkspaceWithCoworkers])
  
    

    return (
        <main className="main">
            {!loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '80vh' }}>
                    <ScaleLoader color="#BF2626" size={140} loading />
                </div>
            ) : (
                <React.Fragment>
                    <div className="main__actions">
                
                        <Link to={`/coworkers/edit/${idCoworker}`}>
                           
                            <p className="main__action">
                                Modifier
                            </p>
                        </Link>
                        <CirclePower color={coworkerData.active ? '#44A677' : '#BF2626' } onClick={handleClick} />
                    </div>
                    <div className="main__back" onClick={()=>navigate(-1)}>
                        <ChevronLeft />
                        <p>Retour</p>
                    </div>
                    <section className="coworker__section main__section">
                        <section>
                            <h2 className="coworker__title">Informations sur <span> {coworkerData?.surname} </span></h2>
                          
                            <ul className="coworker__infos">
                                <li className="coworker__info"><a href={`mailto:${coworkerData?.email}`}><span>Email</span>&nbsp; : {coworkerData?.email}</a></li>
                                <li className="coworker__info"><span>Nom et Prénom </span>&nbsp;: {coworkerData?.surname}</li>
                                <li className="coworker__info"><span>Date de naissance</span>&nbsp; : { coworkerData?.birthday ?  format(new Date(coworkerData?.birthday), "dd MMMM yyyy", { locale: frLocale }) : 'Non mentionné'}</li>
                                <li className="coworker__info"><span>Téléphone </span>&nbsp;: {coworkerData?.contact || 'Non mentionné'}</li>
                                <li className="coworker__info"><span>Fonction</span>&nbsp;: {coworkerData?.job || 'Non mentionné'}</li>
                                <li className="coworker__info"><span>Date d'arrivée</span>&nbsp;: {coworkerData?.arrivedAt ? format(new Date(coworkerData?.arrivedAt), "dd MMMM yyyy", { locale: frLocale }) : 'Non mentionné'}</li>
                                <li className="coworker__info">
                                    <span>Actif.ve : </span>&nbsp; 
                                    <img 
                                        src={coworkerData?.active ? yesIcon : noIcon} 
                                        alt="Icone" 
                                        className="coworker__icon"
                                    />
                                </li>

                                <li className="coworker__info">
                                    <span>Contrat Associé : </span> &nbsp;
                                    {coworkerData.contractFile ?
                                        <a href={`https://admin.9coworking.fr/pdf/${coworkerData?.contractFile}`} target="_BLANK" rel="noreferrer">Voir le contrat</a>
                                        :
                                        "Pas de contrat enregistré pour l'instant"
                                    }
                                </li>
                                <li className="coworker__info coworker__info--column">
                                    <span>Notes :</span>&nbsp; 
                                    <p className="coworker__notes">{coworkerData.notes.trim() === "" ? "Aucune note pour le moment..." : coworkerData.notes}</p></li>
                                <li className="coworker__info">
                                    <MyImage image={{
                                        src : getProfilUrl(coworkerData?.file),
                                        alt : "Profil du coworker",
                                        width : '200px'
                                    }} />
                               
                                </li>
                               
                            </ul>
                        </section>
           
                        <section className="coworker__currentBooking">
                            <article>
                                <h2 className="coworker__title">Forfait en cours</h2>
                                {currentBooking === null || Object.keys(currentBooking).length === 0 ? 
                                    <div className="coworker__message">
                                          <p className="coworker__error">Désolé, ce coworker n'a aucun forfait en cours...</p> 
                                          <p className="coworker__action" onClick={()=>setForfaitModalOpen(true)}>Ajouter un forfait</p>
                                    </div>
                                  
                                    :   
                                    <>
                                        <ul className="coworker__infos">
                                            <li className="coworker__info"><span>Nom du forfait : </span>&nbsp;{currentBooking?.name}</li>
                                            <li className="coworker__info"><span>Type de forfait : </span>&nbsp;{currentBooking?.type.name}</li>
                        
                                            <li className="coworker__info"><span>Date de début : </span>&nbsp;{currentBooking?.start_date ? format(new Date(currentBooking?.start_date), "dd MMMM yyyy", { locale: frLocale }) : 'Non mentionné'}</li>
                                            <li className="coworker__info"><span>Date de fin : </span>&nbsp;{currentBooking?.end_date ? format(new Date(currentBooking?.end_date), "dd MMMM yyyy", { locale: frLocale }) : 'Non mentionné'}</li>
                                            {currentBooking.duration_type.name === 'Mois' && 
                                                    <li className="coworker__info">
                                                        <span>Emplacement : </span>&nbsp;
                                                        <p>&nbsp;
                                                        {currentBooking.workspace
                                                            ? `${currentBooking.workspace.name} - ${currentBooking.workspace.building?.name ?? 'Nom du bâtiment non disponible'}`
                                                            : 'Non mentionné'}
                                                        </p>
                                                        <Pencil size={20} onClick={()=>setIsworkspaceModalOpen(true)}/>
                                                    </li>
                                            }
                                            
                                            <li className="coworker__info"><span>Jours restants : </span>&nbsp;{currentBooking?.remainDays !== null ? currentBooking?.remainDays : 'Non applicable'}</li>
                                        
                                        </ul>
                                        <br /><br /><br /><br />
                                        <table className="main__table">
                                            <thead className="main__table-head">
                                                <tr>
                                                    <th>Jour</th>
                                                    <th>Etat</th> 
                                                </tr>
                                            </thead>
                                            {days?.length > 0 && (
                                                <tbody className="main__table-body">
                                                    {days.map((day, index) => (
                                                        <tr key={index}>
                                                            <td>{format(new Date(day.date), 'dd MMMM yyyy', { locale: frLocale })}</td>
                                                            <td className={`status-cell ${!day.present ? 'red' : 'green'}`}>
                                                                {day.present ? 'Présent.e' : 'Absent.e'} 
                                                        
                                                                <Minus color="#000" className="minus" onClick={()=> handleClickMinus(day.date)}/>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            )}
                                        </table>
                                        {currentBooking?.remainDays <=0 && 
                                        
                                        <div className="bookingStatus">
                                            <p className="completed">Forfait terminé !</p>
                                            <p className="buttonNew" onClick={handleCreateNewBooking}> Renouveller</p>
                                        </div>
                                                
                                                }
                                    </>
                                 
                                }
                                           
                            </article>
                            
                            {currentBooking?.remainDays > 0 && 
                                <article>
                                    <form className="validation form" onSubmit={handleValidation}>
                                            
                                            <h2 className="coworker__title">Validation de présence ou absence</h2>
                                    
                                            <input type="date" 
                                                min={`${currentBooking?.start_date?.split('T')[0]}`}
                                                max={`${currentBooking?.start_end?.split('T')[0]}`}
                                                value={attendance.date}
                                                onChange={(e)=>setAttendance({...attendance, date : e.target.value})}
                                                className="form__input"
                                                required
                                            />
                                    
                                            {currentBooking?.duration_type.name === '10 jours' && 
                                                <select 
                                                name="workspace" 
                                                id=""  
                                                onChange={(e) => setAttendance({ ...attendance, workspace: e.target.value })}
                                                className="form__input"
                                                required
                                                >
                                                    <option value="" required hidden>Choisir un lieu de travail</option>
                                                    {freeWorkspaces
                                                    ?.filter(w => !w.coworker && w.type.name === 'Poste fixe')
                                                    .map(workspace => <option key={workspace.id} value={`/api/workspaces/${workspace.id}`}>
                                                                                    { workspace.name} - {workspace.building.name}
                                                                                    </option>
                                                            )}
                                                </select>
                                            }
                                            

                                            <div className="validation__attendance">
                                                <p>Absent</p>
                                                <Switch
                                                    checked={attendance.present}
                                                    onChange={(e) => setAttendance({...attendance, present: e.target.checked})}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    color="error"
                                                />
                                                <p>Présent</p>
                                            </div>
                                  
                                    
                                            <button className="validation__action"  type="submit">
                                                Ajouter
                                            </button>
                                    </form>
                                </article>
                            }
                            
                       
                           
                        </section>
                       
                    </section>
                    <section>
                        <h2 className="coworker__title">Historique des forfaits</h2>
                        {bookings
                        ?.filter(booking => booking.id !== currentBooking.id && booking.coworker.id === idCoworker )
                        .map(booking => 
                        
                                    <Accordion >
                                        <AccordionSummary
                                        expandIcon={<ChevronDown />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        >
                                        <Typography sx={{ width: '33%', flexShrink: 0, fontFamily: 'Poppins', fontWeight:'500', fontSize: 'clamp(12px, 1vw, 20px)' }}>
                                            {booking.name} 
                                        </Typography>
                                        
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Typography sx={{ fontFamily: 'Poppins', fontSize: 'clamp(12px, 0.9vw, 15px)' }}>
                                            <ul>
                                            {attendances
                                            .filter(attendance => attendance.booking.id === booking.id)
                                            .map(attendance => {
                                                return <li key={attendance.id}> {attendance.workspace.name}({attendance.workspace.building.name}) {!attendance.present && '- Absent'} - {format(new Date(attendance.date), "dd MMMM yyyy", { locale: frLocale })}</li>
                                            })}
                                            </ul>
                                            
                                        </Typography>
                                        </AccordionDetails> 
                                    </Accordion>
                    )}
                    </section>
                </React.Fragment>
            )}

            <Modal isOpen={isForfaitModalOpen} onClose={()=>setForfaitModalOpen(false)}>
                
                <form action="" className="form" onSubmit={handleAddBookings}>
                    <h2 className="form__title">Ajouter un forfait</h2>
                    <input 
                        type="text" 
                        name="name" 
                        placeholder="Entrez le nom" 
                        value={bookingData.name} 
                        className="form__input" 
                        onChange={handleChangeBooking}
                        required
                    />
              
                    <select 
                        name="coworker" 
                        id="coworker" 
                        className="form__input" 
                        value={bookingData.coworker} 
                        onChange={handleChangeBooking}
                        required
                    >
                        <option value={coworkerData?.id}>{coworkerData?.surname}</option>
                       
                    </select>

                    <select 
                        name="type" 
                        id="type" 
                        className="form__input" 
                        value={bookingData.type} 
                        onChange={handleChangeBooking}
                        required
                    >
                        <option value="">Type</option>
                        {
                            bookingTypes?.map((type, index) => {
                                return <option key={`${Date.now()} - ${index}`} value={`/api/booking_types/` + type.id}>{type.name}</option>
                            })
                        }
                    </select>

                    <select 
                        name="duration_type" 
                        id="duration_type" 
                        className="form__input" 
                        value={bookingData.duration_type} 
                        onChange={handleChangeBooking}
                        required
                    >
                        <option value="">Type de durée</option>
                        {
                            durationTypes?.map((type, index) => {
                                return <option key={`${Date.now()} - ${index}`} value={`/api/booking_duration_types/` + type.id}>{type.name}</option>
                            })
                        }
                    </select>

                   
                    <input 
                        type="date" 
                        name="start_date" 
                        value={bookingData.start_date} 
                        className="form__input" 
                        placeholder="choisir une date" 
                        onChange={handleChangeBooking}
                        required
                    />

                    <input 
                        type="date" 
                        name="end_date" 
                        value={bookingData.end_date} 
                        className="form__input" 
                        placeholder="choisir une date" 
                        onChange={handleChangeBooking}
                        required
                    />

                    <button type="submit" className="form__submit">
                            Valider
                    </button>
                </form>
            </Modal>   

            <Modal isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)} >
               <div className="layout__logout">
               <h2 className="title">Etes vous sûr de vouloir modifier cette présence ?</h2>
               <p className="validate" onClick={handleValidation}>
                  Oui
               </p>
               
               </div>
                 
             </Modal>
             <Modal isOpen={isworkspaceModalOpen} onClose={()=>setIsworkspaceModalOpen(false)}>
                    <form action="" className="form" onSubmit={handleAddAssignment}>
                        <h2 className="form__title">Attribution d'un espace</h2>
                        <select 
                            name="workspace" 
                            id="" 
                            className="form__input"
                           
                        >
                            <option value="" hidden>Choisir un espace de travail</option>
                            {workspaces
                            .filter(workspace => workspace.type.name === 'Poste fixe')
                            .map(workspace => {
                                return <option selected={currentBooking?.workspace?.id === workspace.id} key={workspace.id}  value={`/api/workspaces/${workspace.id}`}>{workspace.name} - {workspace.building.name}</option>
                            })}
                        </select>

                        <button type="submit" className="form__submit"> Valider</button>
                    </form>
                    
             </Modal>
            {alert.show && (
                <Alert severity={alert.severity} message={alert.message} />
            )}
        </main>
    );
}

export default ShowCoworker;
