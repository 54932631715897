
const urlBookings = `${process.env.REACT_APP_API_URL}bookings`;
const urlBookingDurationType = `${process.env.REACT_APP_API_URL}booking_duration_types`;

export const showBookings = async () => {
    const token = localStorage.getItem('token'); // Récupérer le token JWT depuis le stockage local
    let allBookings  = [];
    const baseUrl = 'https://admin.9coworking.fr';
    try{
        let nextPageUrl = urlBookings;
    
        while (nextPageUrl){
            const response = await fetch(`${nextPageUrl}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/ld+json',
                },
             });
    
             if (!response.ok) {
                throw new Error('Erreur lors de la récupération des forfaits');
            }
    
            const data = await response.json();
    
            allBookings = allBookings.concat(data['hydra:member']);
            const view = data["hydra:view"];
      
            if (view && view['hydra:next']) {
                nextPageUrl = `${baseUrl}${view['hydra:next']}`;
            } else {
                nextPageUrl = null;
            }
          
        }

        return allBookings;
    }catch (error){
        console.error("Erreur lors de la récupération des forfaits'", error);
        throw error; 
    }
  
}


export const addBookings = async (bookingData) => {
    const token = localStorage.getItem('token'); // Récupérer le token JWT depuis le stockage local
 
    try{
          const response = await fetch(`${urlBookings}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/ld+json',
            },
            body: JSON.stringify(bookingData), // Les données du coworker à ajouter
         });

         if (!response.ok) {
            throw new Error('Erreur lors de la récupération des forfaits');
        }

        const data = await response.json();
        return data

    }catch (error){
        console.error("Erreur lors de la récupération des forfaits'", error);
        throw error; 
    }
  
}


export const deleteBooking = async (bookingId) => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${urlBookings}/${bookingId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
               
            },
        });
        
        if (!response.ok) {
            throw new Error('Echec');
        }

        return response.json();
    } catch (error) {
        throw new Error('Erreur lors de la suppression du forfait : ' + error.message);
    }
}


export const showDurationType = async() => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${urlBookingDurationType}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
               
            },
        });
        
        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des présences');
         }
         const data = await response.json();
     
        return data['hydra:member'];
    } catch (error) {
        throw new Error('Erreur' + error.message);
    }
}


export const editBooking = async(bookingData, id) => {

    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${urlBookings}/${id}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/merge-patch+json',
               
            },
            body : JSON.stringify(bookingData)
        });
        
        if (!response.ok) {
            throw new Error('Erreur lors de la récupération des présences');
         }
         const data = await response.json();
     
        return data;
    } catch (error) {
        throw new Error('Erreur' + error.message);
    }

}