
import { Check, TriangleAlert } from 'lucide-react';
const Alert = (props) => {
    
    const {message, severity} = props;

    return <div className= {`alert ${severity === "success" ? 'alert--success' : 'alert--echec'}`}>
         
        {severity === 'success' ? <Check /> : <TriangleAlert /> }
        <p className="alert__message">{message}</p>
    </div>
}

export default Alert;