
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editCoworker } from "../hooks/useDbCoworker";
import emailjs from 'emailjs-com';
import { removeEmptyProperties } from "../hooks/useTools";
import Alert from "../components/Alert";

import { addCoworkers, 
         fetchCoworker, 
         updateCoworker, 
         handleChange, 
         generateRandomPassword, 
         removedCoworkerData} from "../features/coworkerSlice";
import { ScaleLoader } from "react-spinners";
import { ChevronLeft, Eye, EyeOff } from "lucide-react";


const EditCoworker = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const [selectedFile, setSelectedFile] = useState();
    const idCoworker = params?.idCoworker;
    const { coworkerData, allCoworkers } = useSelector(state => state.coworker);


    const [loading, setLoading] = useState(true);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [alert, setAlert] = useState({ show: false, severity: "", message: "" });

    const navigate = useNavigate()

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false)
        }, 2000)
        if (idCoworker) {
            dispatch(fetchCoworker({ id: idCoworker }));
        }else {
            dispatch(removedCoworkerData())
        }
        
    }, [idCoworker, dispatch]);


    //Fonction de submit des informations de coworker
    const handleSubmitCoworker = async (e) => {
        e.preventDefault();
        try {
           
            const nb = Object.assign({}, coworkerData);
            const file = coworkerData.files;
            const name = coworkerData.file
            delete nb.files;

            //Fetch de la photo en base46

            const formData = new FormData();
            formData.append(name, file)

            if (file){
                
                fetch(`https://admin.9coworking.fr/uploads`, {
                    method: 'POST',
                    body: formData,
                })
                .then(response => response.json())
                .then(data => {
                    console.log('File uploaded successfully:', data);
                    // Optionnel : Mettre à jour l'état avec les informations du fichier uploadé
                })
                .catch(error => console.error('Error uploading file:', error));
              
            }
            
            if (!idCoworker) {
                const alreadyAdded = false
          
                if (!alreadyAdded) {
                   
                    dispatch(addCoworkers({ data: nb }));
                    showAlert("success", "Coworker ajouté avec succès !");
                  
                } else {
                    showAlert("error", "Un coworker avec cet email est déjà enregistré.");
                }
            } else {
                const propertyRemoved = removeEmptyProperties(coworkerData)
                await editCoworker(propertyRemoved, idCoworker);
                dispatch(updateCoworker({data : coworkerData, id : idCoworker}))
                showAlert("success", "Coworker modifié avec succès !");
            }
            
            sendEmail(coworkerData.email,  coworkerData.surname, coworkerData.password);
        } catch (error) {
            console.error("Erreur lors de l'ajout ou de la modification du coworker :", error);
            showAlert("error", "Une erreur s'est produite lors de l'opération.");
        }
    };

    //Fonction d'envoie d'email
    const sendEmail = (email, name, psw) => {
        console.log(isSendingEmail)
        if (isSendingEmail) {
            console.log('oh')
            emailjs.send('service_p79iek9', 'template_hqikmgh', {
                to_name: name,
                to_email: email,
                link : "https://coworker.9coworking.fr/",
                password : psw,
            }, 'ejw0IPqccW0I8uyrd')
            .then((response) => {
                console.log('E-mail envoyé avec succès:', response);
                showAlert("success", "E-mail envoyé avec succès !");
            })
            .catch((error) => {
                console.error('Erreur lors de l\'envoi de l\'e-mail:', error);
                showAlert("error", "Erreur lors de l'envoi de l'e-mail.");
            });
        }
    };

    const showAlert = (severity, message) => {
        setAlert({ show: true, severity: severity, message: message });
        setTimeout(() => {
            setAlert({ show: false, severity: "", message: "" });
        }, 3000);
    };

    const handleChangeData = (e) => {
        const { name, value, files } = e.target;
    
        if (files && files.length > 0) {
            const file = files[0];
            console.log(file);
            setSelectedFile(file);
    
            const reader = new FileReader();
            reader.onload = () => {
                dispatch(handleChange({ name: name, value: value, fileName: file.name, fileData: reader.result }));
            };
            reader.readAsDataURL(file);
        } else {
            // Si aucun fichier n'est sélectionné, dispatch uniquement avec name et value
            dispatch(handleChange({ name: name, value: value, fileName: null, fileData: null }));
        }
    
        console.log(coworkerData);
    };
    


    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <ScaleLoader
                    color="#BF2626"
                    size={100}
                    loading
                />
            </div>
        )
    }


    return (
        <main className="main">
            <form action="" className="form" onSubmit={handleSubmitCoworker}>

                <div className="main__back" onClick={()=>navigate(-1)}>
                    <ChevronLeft />
                    <p>Retour</p>
                </div>
             
                <h1 className="form__title">{idCoworker ? 'Modifier' : 'Ajouter'} un coworker</h1>
              
                <div className="form__group">
                    <label htmlFor="email" className="form__label">Email</label>
                    <input 
                        type="email" 
                        name="email" 
                        id="email"
                        required 
                        placeholder="Ajouter un email" 
                        className="form__input" 
                        onChange={handleChangeData} 
                        value={coworkerData.email} 
                    />
                </div>
               
                <div className="form__group form__group--row">
               
                    <div className="form__group--password">
                        <input 
                            type={showPassword ? "text" : "password"} 
                            name="password" 
                            id="password"
                            placeholder="Ajouter un mot de passe" 
                            className="form__input" 
                            onChange={handleChangeData} 
                            value={coworkerData.password} 
                            required = {!idCoworker && true}
                        />
                      
                        {!showPassword ? <Eye className="form__eyeIcon" onClick={() => setShowPassword(!showPassword)}/> 
                                      : <EyeOff className="form__eyeIcon" onClick={() => setShowPassword(!showPassword)}/>}
                    </div>
            
                    <p className="form__generate" onClick={()=>dispatch(generateRandomPassword(20))}>Générer</p>
               </div>
               
               <div className="form__group">
                <label htmlFor="surname" className="form__label">Nom et prénom</label>
                    <input 
                        type="text" 
                        name="surname" 
                        required 
                        placeholder="Ajouter un nom et prénom" 
                        className="form__input" 
                        onChange={handleChangeData}
                        value={coworkerData.surname} 
                     />
               </div>
              
               <div className="form__group">
                <label htmlFor="job" className="form__label">Profession</label>
                    <input 
                        type="text" 
                        name="job" 
                        id="job"
                        placeholder="Ajouter une profession" 
                        className="form__input" 
                        onChange={handleChangeData} 
                        value={coworkerData.job} 
                    />
               </div>
             
                <div className="form__group">
                    <label htmlFor="contact" className="form__label">Téléphone</label>
                    <input 
                        type="text" 
                        name="contact" 
                        id="contact"
                        placeholder="Ajouter un téléphone" 
                        className="form__input" 
                        onChange={handleChangeData} 
                        value={coworkerData.contact} 
                    />
                </div>
               
                <div className="form__group">
                    <label htmlFor="arrivedAt" className="form__label">Date d'arrivée</label>
                    <input 
                        type="date" 
                        name="arrivedAt" 
                        required 
                        className="form__input" 
                        onChange={handleChangeData}
                        value={coworkerData?.arrivedAt} 
                    />
                </div>

                <div className="form__group" >
                    <label htmlFor="birthday" className="form__label">Date de Naissance</label>
                    <input 
                        type="date" 
                        name="birthday" 
                        required 
                        className="form__input" 
                        onChange={handleChangeData} 
                        value={coworkerData?.birthday} />
                </div>

                <div className="form__group">
                    <label htmlFor="notes" className="form__label">Notes</label>
                    <textarea 
                        name="notes" 
                        onChange={handleChangeData}
                        id="notes" 
                        placeholder="Entrez les notes ici..." 
                        value={coworkerData?.notes}
                        rows="15" 
                        className="form__input form__input--backgroundcolor"
                    >

                    </textarea>
                </div>

                <div className="form__group">
                    <input type="file" name="file" onChange={handleChangeData} accept="image/*"/>
                </div>
                
                <div className="form__radioButton">
                    <div>
                        <input type="radio" id="huey" name="roles" value="ROLE_ADMIN" />
                        <label htmlFor="huey">Admin</label>
                    </div>
                    <div>
                        <input type="radio" id="dewey" name="roles" value="ROLE_USER" defaultChecked />
                        <label htmlFor="dewey">User</label>
                    </div>
                </div>
                {idCoworker &&
                    <div className="form__radioButton">
                        <p>Actif.ve ? </p><br />
                        <div>
                            <label htmlFor="yes">Oui</label>
                            <input type="radio" id="yes" name="isActive" value="true" checked={coworkerData?.isActive } onChange={handleChangeData} />&nbsp;&nbsp;&nbsp;
                            <label htmlFor="no">Non</label>
                            <input type="radio" id="no" name="isActive" value="false" checked={!coworkerData?.isActive} onChange={handleChangeData} />
                        </div>
                    </div>
                }
                {/* Si on est en edit */}
                {(!idCoworker || coworkerData.password) &&
                    <div className="">
                        <input type="checkbox" id="scales" name="scales" defaultChecked={isSendingEmail} onChange={() => setIsSendingEmail(!isSendingEmail)} />
                        <label htmlFor="scales">Envoyer les identifiants au coworker</label>
                    </div>
                }
                
                
                <button type="submit" className="form__submit">Valider</button>
            </form>
            {alert.show && (
                <Alert severity={alert.severity} message={alert.message} />
            )}
        </main>
    );
};

export default EditCoworker;
